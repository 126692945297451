(function () {

    'use strict';

    var ScrollTracker = function (tracker, target) {

        var that = this;

        if (document.getElementById(tracker)) {

            this.wrapper = document.getElementById(tracker);
            this.handler = this.wrapper.getElementsByClassName('handle')[0];
            this.tracker = this.wrapper.getElementsByClassName('track')[0];

            this.target = target;

            this.is_grabbed = false;

            this.touch_orig = 0;

            this.mv_origin = 0;
            this.mv_offset = 0;

            this.handler.addEventListener('mousedown', function (evt) {

                ScrollTracker.prototype.grab.call(that, evt);
            });

            window.addEventListener('mouseup', function (evt) {

                ScrollTracker.prototype.release.call(that, evt);
            });

            window.addEventListener('mousemove', function (evt) {

                ScrollTracker.prototype.move.call(that, evt);
            });

            this.tracker.addEventListener('click', function (evt) {

                ScrollTracker.prototype.moveTo.call(that, evt);
            });
        } else {

            that = false;

            console.error('Either tracker or target element is missing');
        }

        return that;
    };

    ScrollTracker.prototype.grab = function (evt) {

        evt.preventDefault();

        this.is_grabbed = true;
        this.touch_orig = evt.pageX;

        this.handler.classList.add('grabbed');

        return evt;
    };

    ScrollTracker.prototype.release = function (evt) {

        evt.preventDefault();

        this.is_grabbed = false;

        this.handler.classList.remove('grabbed');

        return evt;
    };

    ScrollTracker.prototype.move = function (evt) {

        evt.preventDefault();

        if (this.is_grabbed) {

            this.mv_origin = parseFloat(this.handler.style.left) || 0,
            this.mv_offset = this.mv_origin + (evt.pageX - this.touch_orig);

            this.touch_orig = evt.pageX;

            if (this.mv_offset >= 0 && this.mv_offset <= this.tracker.offsetWidth && this.target.max_scroll < 0) {

                this.pc_offset = (this.mv_offset / this.tracker.offsetWidth) * 100;

                ScrollTracker.prototype.moveTarget.call(this.target, this.pc_offset);

                this.handler.style.left = this.mv_offset + 'px';
            }
        }

        return evt;
    };

    ScrollTracker.prototype.moveTo = function (evt) {

        evt.preventDefault();

        this.mv_origin = this.handler.offsetWidth / 2,
        this.mv_offset = evt.pageX - this.mv_origin;

        this.touch_orig = evt.pageX;

        this.pc_offset = (this.mv_offset / this.tracker.offsetWidth) * 100;

        ScrollTracker.prototype.moveTarget.call(this.target, this.pc_offset);

        this.handler.style.left = this.mv_offset + 'px';

        return evt;
    };

    ScrollTracker.prototype.moveTarget = function (pct) {

        this.elm_slider.style.left = (this.max_scroll * (pct / 100)) + 'px';

        this.computePercent();
        this.computePage();

        if (((this.max_scroll * (pct / 100)) <= this.max_scroll + 170) && (window.infinity !== undefined)) {

            infinity.fn.load();
        }
    };

    window.ScrollTracker = ScrollTracker;
}());
