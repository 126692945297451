//**************************************************************
// jQZoom allows you to realize a small magnifier window,close
// to the image or images on your web page easily.
//
// jqZoom version 2.2
// Author Doc. Ing. Renzi Marco(www.mind-projects.it)
// First Release on Dec 05 2007
// i'm looking for a job,pick me up!!!
// mail: renzi.mrc@gmail.com
//**************************************************************

(function($){

		$.fn.jqueryzoom = function(options){

		var settings = {
				xzoom: 200,		//zoomed width default width
				yzoom: 200,		//zoomed div default width
				offset: 10,		//zoomed div default offset
				position: "right" ,//zoomed div default position,offset position is to the right of the image
				lens:1, //zooming lens over the image,by default is 1;
				preload: 1

			};

			if(options) {
				$.extend(settings, options);
			}

		    var noalt='';

		    $(this).hover(function(){

			$(".inputForm").each(function(){
				$(this).hide();
			});

			var imageLeft = $(this).offset().left;

			var imageTop = $(this).offset().top;

		    var imageWidth = $(this).children('img').get(0).offsetWidth;
		    var imageHeight = $(this).children('img').get(0).offsetHeight;

            noalt= $(this).children("img").attr("alt");

		    var bigimage = $(this).children("img").attr("jqimg");

			var tmp_string = bigimage;
			tmp_string = tmp_string.replace(/medium/,"large");

            $(this).children("img").attr("alt",'');

		    if($("div.zoomdiv").get().length == 0){

				var wait = false;
				$(this).after("<div class='zoomdiv'><div style='z-index:1;position:relative;top:50px;'><img src='/img/ajaxLoader.gif' alt=\"Chargement en cours...\" width=\"25\" height=\"25\" /></div><div style='position:relative;z-index:2;top:0px;margin-top:-21px;'><img class='bigimg' src='"+bigimage+"'/></div></div>");
				$(this).append("<div onclick='test();' class='jqZoomPup'>&nbsp;</div>");
		    }

		    if(settings.position == "right"){

            if(imageLeft + imageWidth + settings.offset + settings.xzoom > screen.width){

            leftpos = imageLeft  - settings.offset - settings.xzoom;

            }else{

		    leftpos = imageLeft + imageWidth + settings.offset;
            }
		    }else{
		    leftpos = imageLeft - settings.xzoom - settings.offset;
		    if(leftpos < 0){

            leftpos = imageLeft + imageWidth  + settings.offset;

		    }

		    }

			if(tab_js[cpt_img][1]==0) leftpos -= 30;

			var new_top = 49;
			if(imageHeight == 435)
				var new_top = 65;
			if( type == 'gift' ) new_top = 0;
			if ( navigator.userAgent.indexOf('MSIE') != -1 )
			{
				if( navigator.userAgent.indexOf('8') != -1 )
					// IE 8
					new_top -= 1;
				else
					// IE != 8
					new_top -= 184;
			}

			var new_left = 5;
			if(imageWidth == 435)
				var new_left = 85;

			if ( navigator.userAgent.indexOf('MSIE') != -1 )
			{
				if( navigator.userAgent.indexOf('8') == -1 )
				{
					// IE != 8
					if(imageWidth == 360)
					{
						// format carre
						new_left += 351;
					}
					else
					{
						// format rectangle
						new_left += 329;
					}
				}
			}

		    $("div.zoomdiv").css({ top: (imageTop+new_top),left: (leftpos-new_left) });

		    $("div.zoomdiv").width(settings.xzoom);

		    $("div.zoomdiv").height(settings.yzoom);

            $("div.zoomdiv").show();

            if(!settings.lens){
              $(this).css('cursor','crosshair');
			}




				   $(document.body).mousemove(function(e){



                   mouse = new MouseEvent(e);

                   /*$("div.jqZoomPup").hide();*/


				    var bigwidth = $(".bigimg").get(0).offsetWidth;

				    var bigheight = $(".bigimg").get(0).offsetHeight;

				    var scaley ='x';

				    var scalex= 'y';


				    if(isNaN(scalex)|isNaN(scaley)){

				    var scalex = (bigwidth/imageWidth);

				    var scaley = (bigheight/imageHeight);




				    $("div.jqZoomPup").width((settings.xzoom)/scalex );

		    		$("div.jqZoomPup").height((settings.yzoom)/scaley);


					if(scalex<1 || scaley<1) {
						$("div.jqZoomPup").width(0);
						$("div.jqZoomPup").height(0);
					}

                    if(settings.lens){
                    $("div.jqZoomPup").css('visibility','visible');
					}

				   }



                    xpos = mouse.x - $("div.jqZoomPup").width()/2 - imageLeft;

                    ypos = mouse.y - $("div.jqZoomPup").height()/2 - imageTop ;

                    if(settings.lens){

                    xpos = (mouse.x - $("div.jqZoomPup").width()/2 < imageLeft ) ? 0 : (mouse.x + $("div.jqZoomPup").width()/2 > imageWidth + imageLeft ) ?  (imageWidth -$("div.jqZoomPup").width() -2)  : xpos;

					ypos = (mouse.y - $("div.jqZoomPup").height()/2 < imageTop ) ? 0 : (mouse.y + $("div.jqZoomPup").height()/2  > imageHeight + imageTop ) ?  (imageHeight - $("div.jqZoomPup").height() -2 ) : ypos;

                    }


                    if(settings.lens){

                    $("div.jqZoomPup").css({ top: ypos,left: xpos });

                    }



					scrolly = ypos;

					$("div.zoomdiv").get(0).scrollTop = scrolly * scaley;

					scrollx = xpos;

					$("div.zoomdiv").get(0).scrollLeft = (scrollx) * scalex ;


				    });
		    },function(){

               $(this).children("img").attr("alt",noalt);
		       $(document.body).unbind("mousemove");
		       if(settings.lens){
		       $("div.jqZoomPup").remove();
		       }
		       $("div.zoomdiv").remove();
			   $(".inputForm").each(function(){
					$(this).show();
				});

		    });

        count = 0;

		if(settings.preload){

		$('body').append("<div style='display:none;' class='jqPreload"+count+"'>sdsdssdsd</div>");

		$(this).each(function(){

        var imagetopreload= $(this).children("img").attr("jqimg");

        var content = jQuery('div.jqPreload'+count+'').html();

        jQuery('div.jqPreload'+count+'').html(content+'<img src=\"'+imagetopreload+'\">');

		});

		}

		}

})(jQuery);

function MouseEvent(e) {
this.x = e.pageX;
this.y = e.pageY;


}

function test(){
	jQuery('#loupe_plus').click();
}
