var save_id = 0;
var cpt_save = 0;
var from_range = false;  //for wishlist

function addEvent(elem, event, name_function) {

    "use strict";

    if (document.all) {

        elem.attachEvent("on" + event, name_function);
    } else {

        elem.addEventListener(event, name_function, false);
    }
}

function delEvent(elem, event, name_function) {

    "use strict";

    if (document.all) {

        elem.detachEvent("on" + event, name_function);
    } else {

        elem.removeEventListener(event, name_function, false);
    }
}

// empeche les evenements de remonter dans l'arbre
function doNothing(e) {

    "use strict";

    if (document.all) {

        if (!e) {

            e = window.event;
        }

        e.cancelBubble = true;
    } else {

        e.stopPropagation();
    }

    return false;
}

// popup guide des tailles
function showsizeguide() {

    "use strict";

    var elname = "sizeguidebox";

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var shad = document.getElementById("shad");

    modbox.style.position = "absolute";
    shad.style.display = "block";

    addEvent(shad, "click", closesizeguide);
    addEvent(document.getElementById("menu_sizeguide"), "click", doNothing);

    modbox.style.display = "block";

    window.scrollTo(0,actualposition);
}

function closesizeguide() {

    "use strict";

    var elname = "sizeguidebox";
    var shad = document.getElementById("shad");
    var modbox = document.getElementById(elname);

    modbox.style.display = "";

    delEvent(shad, "click", closesizeguide);
    delEvent(modbox, "click", closesizeguide);
    delEvent(document.getElementById("menu_sizeguide"), "click", doNothing);

    closemodal();
}

// switch guide des tailles
function switch1(div) {

    "use strict";

    if (document.getElementById("sizeguide_femme")) {

        var option = [
            "sizeguide_femme", "sizeguide_homme", "sizeguide_lingerie",
            "sizeguide_fille", "sizeguide_garcon", "sizeguide_bebe"
        ];

        option.forEach(function (objid) {

            obj = document.getElementById(objid);

            obj.style.display = (objid === div)
                ? "block"
                : "none";
            //
        });
    }

    cpt_save += 1;
}

function showmodal(produit_id) {

    "use strict";

    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById("modbox");
    var cmodbox = document.getElementById("cmodbox");
    var panmodbox = document.getElementById("panmodbox");
    var shad = document.getElementById("shad");

    document.body.style.overflow = "hidden";

    cmodbox.innerHTML = "";
    panmodbox.innerHTML = "";

    modbox.style.marginTop = (actualposition - 260) + "px";
    shad.style.display = "block";
    modbox.style.display = "block";

    document.getElementsByTagName("html")[0].style.overflow = "hidden";

    window.scrollTo(0, actualposition);
}

function newshowmodal(nb_panier, type, path_web, is_not_produit, id_produit) {

    "use strict";

    var actualposition = document.documentElement.scrollTop;

    if (type !== "err") {

        var title = $("#ajax_product_name").html();
        var subtitle = $("#ajax_product_description").html();

        if (is_not_produit) {

            title = $("#ajax_product_name_" + id_produit).html();
            subtitle = $("#ajax_product_description_" + id_produit).html();
        }

        var str_taille = "";
        var str_couleur = "";
        var str_qte = "";

        if (is_not_produit) {

            if(document.getElementById("ligne_taille")) {

                if (document.getElementById("tailleUnique_" + id_produit)) {

                    str_taille = $("#tailleUnique_" + id_produit).html();
                } else if (document.getElementById("tailleProd_" + id_produit)) {

                    str_taille = document.getElementById("tailleProd_" + id_produit).options[document.getElementById("tailleProd_" + id_produit).selectedIndex].text;
                }
            }

            if (document.getElementById("ligne_couleur")) {

                if (document.getElementById("couleurUnique_" + id_produit)) {

                    str_couleur = $("#couleurUnique_" + id_produit).html();
                } else if (document.getElementById("couleurProd_" + id_produit)) {

                    str_couleur = document.getElementById("couleurProd_" + id_produit).options[document.getElementById("couleurProd_" + id_produit).selectedIndex].text;
                }
            }

            $("#modbox_visu").attr("src", $("#ajax_main_visu_prod_" + id_produit).attr("src"));
        } else {

            if (document.getElementById("ligne_taille")) {

                if (document.getElementById("tailleUnique")) {

                    str_taille = $("#tailleUnique").html();
                } else if (document.getElementById("tailleProd")) {

                    str_taille = document.getElementById("tailleProd").options[document.getElementById("tailleProd").selectedIndex].text;
                }
            }

            if (document.getElementById("ligne_couleur")) {

                if (document.getElementById("couleurUnique")) {

                    str_couleur = $("#couleurUnique").html();
                } else if (document.getElementById("couleurProd")) {

                    str_couleur = $("#" + $("#couleurProd").val()).text();
                }
            }

            if (document.getElementById("ligne_qte")) {

                str_qte = document.getElementById("qteProd").options[document.getElementById("qteProd").selectedIndex].text;
            }
        }

        var price = $(".main_prix").html();

        var taille_label = ajax_file(path_web + "ajax_sprintf.php?arg1=product_size");
        var couleur_label = ajax_file(path_web + "ajax_sprintf.php?arg1=coloris");
        var qte_label = ajax_file(path_web + "ajax_sprintf.php?arg1=quantite");
        var basket_add_texte = ajax_file(path_web + "ajax_sprintf.php?arg1=basket_add_success");

        var str_response = "<p class=\"subtitle_product_ajout_panier\">" + subtitle + "</p>";

        str_response += "<p class=\"price_ajout_panier\">" + price + "</p>";
        str_response += "<p class=\"infos_product_ajout_panier\">";

        if (str_qte != "") {

            str_response += "<p class=\"ajout_panier_qty\">" + str_qte + " " + basket_add_texte + "</p>";
        }

        if (str_taille != "") {

            str_response += "<p class=\"ajout_panier_label\">" + taille_label + "</p>\n<p class=\"ajout_panier_value\">" + str_taille + "</p>\n<span class=\"clearfix\"></span>";
        }

        if (str_couleur != "") {

            str_response += "<p class=\"ajout_panier_label\">" + couleur_label + "</p>\n<p class=\"ajout_panier_value\">" + str_couleur + "</p>";
        }

        str_response += "</p>";

        $("#modbox").find("[data-content=title]").html(title);
        $('#cmodbox').html(str_response);
        $('#panmodbox').html("Votre panier contient d&eacute;sormais <strong>" + nb_panier + "</strong> " + ajax_file(path_web + 'ajax_sprintf.php?arg1=article' + ((nb_panier == "1") ? "" : "s" )) + ".");
    }

    if (!document.getElementById("id_look")) {

        show_lightbox("modbox");
    }

    window.scrollTo(0, actualposition);
}

function showsendfriendmodal(product_id, path_web){

    var elname = 'sendfriendbox';

    var actualposition = document.documentElement.scrollTop ;
    var modbox = document.getElementById(elname);
    var shad = document.getElementById('shad');

    document.getElementById('product_sendfriend_nok').innerHTML='';
    document.getElementById('product_sendfriend_ok').innerHTML='';
    document.getElementById('product_sendfriend_nok').style.display='none';
    document.getElementById('product_sendfriend_ok').style.display='none';

    prodGetProductDisplay( product_id, path_web );

        $('#shad').fadeTo("slow", 0.5).queue(function () {
            $('#sendfriendbox').slideDown("slow");
            $(this).dequeue();
        });

    addEvent(shad,'click',closesendfriendmodal);

    window.scrollTo(0,actualposition);
}

function closesendfriendmodal() {
    var modbox = document.getElementById('sendfriendbox');
    var shad = document.getElementById('shad');

    $('#sendfriendbox').slideUp("slow").queue(function () {
        $('#shad').fadeTo("slow", 0);
        $(this).dequeue();
        shad.style.display = 'none';
        modbox.style.display = 'none';
    });
    document.getElementById('sendfriend_alert_mail').style.display="none";
    document.getElementById('sendfriend_prodinfos').style.display="block";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_form_needed').style.display="block";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";

    delEvent(shad,'click',closesendfriendmodal);

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }

    }
}

function closesendfriendmodalfromconfirm() {
    var modbox = document.getElementById('sendfriendbox');
    var shad = document.getElementById('shad');
    modbox.style.marginLeft='';
    shad.style.display="none";
    modbox.style.display="none";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";
    document.getElementById('sendfriend_prodinfos').style.display="block";
    document.getElementById('sendfriend_form_to').style.display="block";
    document.getElementById('sendfriend_form_froms').style.display="block";
    document.getElementById('sendfriend_form_buttons').style.display="block";
    document.getElementById('sendfriend_form_needed').style.display="block";
    document.getElementById('sendfriend_alert_mail').innerHTML="";
    document.getElementById('sendfriend_alert_mail').style.display="none";

    delEvent(shad,'click',closesendfriendmodal);

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }

    }

}

function closemodal() {

    var modbox = document.getElementById('modbox');
    var errbox = document.getElementById('errbox');
    var shad = document.getElementById('shad');
    if (document.getElementById('cmodbox')) {
        var cmodbox = document.getElementById('cmodbox');
        cmodbox.innerHTML='';
    }

    modbox.style.marginLeft='';
    shad.style.display="none";
    modbox.style.display="none";
    errbox.style.display="none";

    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="visible";
        }

    }
}

function waitingAddToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique) {
    
    var id = '';

    if (is_not_produit == true) {
        id += '_' + produit_id;
    }

    if (is_lightbox_specifique) {
        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout('addToBasket("' + produit_id + '", "' + suff + '", "' + panier_id + '", "' + titreObjet + '", "' + idTaille + '", "' + lastTaille + '", "' + idCouleur + '", "' + lastCouleur + '","' + toEval + '", "' + page_name + '", "' + path_web + '", ' + enable_omniture + ', ' + is_not_produit + ')', 1000);
    }

    $("#bloc_btn_active"+id).addClass('cache');
    $("#bloc_btn_loader"+id).attr('class','f_right');
}

function addToBasket(produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit) {

    var id = '';
    if( is_not_produit == true ){
        id += '_' + produit_id;
    }

    prix_id = 'prixU';
    if( is_not_produit == true ) {
        var code_color = $('#couleurUnique_' + produit_id).text();
        var nom_color = $('#couleurUnique_' + produit_id).text();
        var prix_id = 'prixU_'+produit_id;
    }

    var array_panier = newUpdatePanier(produit_id, suff, panier_id, $("#"+prix_id).val(), path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur);

    if (array_panier != false){
        var type = '';

        if (array_panier[1] == 'false' || array_panier.length == 0){
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file( path_web + 'ajax_show_basket.php');
        $("#cart_top").html(response);

        if (array_panier[1] === 0){
            $('#nb_article').html(array_panier[1]);
            omnitureEvent = '';
        }else if (array_panier[1] == 1){
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            window.open(path_relative_root + create_link('order_basket'), 'botFrame');

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        }else{
            $('#nb_article').html(array_panier[1]);
            $('#panier').attr('href', path_web+create_link('order_basket')).attr('style', 'cursor:pointer;');

            window.open(path_relative_root + create_link('order_basket'), 'botFrame');

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal(array_panier[1], type, path_web, is_not_produit, produit_id);

        $("#bloc_btn_active"+id).removeClass('cache');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');

        if (array_panier[1] > 0 && enable_omniture == true){
            void(s.t());
            s.pageName  = "'" + page_name + "'";
            s.products  = ";" + produit_id;
            s.events    = omnitureEvent;
            void(s.t());
        }
    }else {
        $("#bloc_btn_active"+id).removeClass('cache');
        $("#bloc_btn_loader"+id).attr('class','f_right cache');
    }

    eval(toEval);

    if (array_panier != false){
        if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ){
            var svn=document.getElementsByTagName("SELECT");
            for (a=0;a<svn.length;a++){
                svn[a].style.visibility="hidden";
            }
        }
    }
    return false;
}

 function displayOnglet( pDiv ){

    if ( pDiv == "ongletSavoirPlus" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
    }

    if ( pDiv == "ongletCaracteristiques" ) {
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
    }

    if ( pDiv == "ongletGammes" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
        if ( document.getElementById('ongletAssociations') )
            document.getElementById('ongletAssociations').style.display ='none';
    }

    if ( pDiv == "ongletAssociations" ) {
        if ( document.getElementById('ongletCaracteristiques') )
            document.getElementById('ongletCaracteristiques').style.display ='none';
        if ( document.getElementById('ongletSavoirPlus') )
            document.getElementById('ongletSavoirPlus').style.display ='none';
        if ( document.getElementById('ongletGammes') )
            document.getElementById('ongletGammes').style.display ='none';
    }

    if ( document.getElementById(pDiv) )
        document.getElementById(pDiv).style.display ='block';
}

function displayZoom ( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list ){

    var so = new SWFObject(path_web + "zoom.swf", "zoom", "405", "610", "8", "#FFFFFF");
    so.addParam("wmode", "transparent");
    so.addParam("allowScriptAccess", "always");
    so.addVariable("produit_id", product_ref);
    so.addVariable("url", path_web);

    so.addVariable("photo_path_medium", picture_medium);
    so.addVariable("photo_path_mini", picture_mini);

    so.addVariable("photo_path", photo_path);

    so.addVariable("photo_list", photo_list);
    so.addVariable("img_defaut", picture);
    so.write("flashVisuel");

}

function zoom( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list ){

    displayZoom( picture, product_ref, path_web, picture_medium, picture_mini, photo_path, photo_list );

}

function zoomImg(photo, path_web) {

    var elname = 'zoomBox',
        actualposition = document.documentElement.scrollTop ,
        modbox = document.getElementById(elname),
        shad = document.getElementById('shad_zoom'),
        str_img = '';

    modbox.style.position = "absolute";
    shad.style.display="block";
    modbox.style.display="block";

    str_img +=
        '<div id="wrapper_zoombox">' +
        '<div class="f_left wrapper_vgt">' +
        '<div id="wrapper_vgt_visus" class="clearfix">' +
        '<a class="close_pop_ajout_panier zoom" href="#" onclick="return closeZoom()"></a>';

    var cnt_tab_js = tab_js.length;
    var i = 0;
    var is_visu_detail = true;

    while( is_visu_detail && i<cnt_tab_js ) {
        is_visu_detail = ( tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'A' || tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'B' || tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'C' || tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'D' || tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'E' || tab_js[i][0].substr(tab_js[i][0].lastIndexOf('.')-1,1) == 'F' );
        if( is_visu_detail ) {

            var photo_large = tab_js[i][0].replace(/medium/,"large");
            var photo_mini = tab_js[i][0].replace(/medium/,"mini");

            str_img += '<img class="zoom_thumb" src="' + photo_mini + '" ale="" onclick="changeVisuZoom(\'bloc_photo_zoom\', \'' + photo_large + '\', \'' + path_web + '\')" />';
            i++;
        }
    }

    str_img += '</div>';

    if( i < cnt_tab_js ) {

        if( document.getElementById('ligne_couleur_label') ) {

            var label_color = document.getElementById('ligne_couleur_label').innerHTML;

            str_img += '<div id="wrapper_vgt_color">' + label_color + '<br /><br />';
            var k = 0;
            for( j=i ; j<cnt_tab_js ; j++,k++ ) {
                var photo_large = tab_js[j][0].replace(/medium/,"large");
                str_img += '<img style="cursor:pointer;" class="no_border" width="60" onclick="changeVisuZoom(\'bloc_photo_zoom\',\''+photo_large+'\',\''+path_web+'\');" src="'+tab_js[j][0]+'" />';
                if( typeof(tab_js_color[k]) != 'undefined' ) str_img += '<img onclick="changeVisuZoom(\'bloc_photo_zoom\',\''+photo_large+'\',\''+path_web+'\');" style="border:0;padding:0;width:70px;height:10px;cursor:pointer;" src="'+tab_js_color[k]+'" />';

            }

            str_img += '</div>';

        }

    }

    str_img += '</div>';
    str_img += '<div id="bloc_photo_zoom" style="background:white;" class="f_left"><div style="position:relative;z-index:2;top:0px;"><img class="no_border" alt="" id="photo_zoom" /></div></div><div onClick="closeZoom()" id="fermer_zoombox" class="btn_noir f_left"></div></div>';

    modbox.innerHTML = str_img;

    modbox.style.top = "50%";
    modbox.style.left = "50%";
    modbox.style.border = "1px solid #EFEFEF";
    modbox.style.margin = "-402px 0 0 -684px";

    window.scrollTo(0,actualposition);

    // Changement de l'image
    changeVisuZoom('bloc_photo_zoom', photo);

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {
        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="hidden";
        }
    }
}

function changeVisuZoom( field, path ) {

    var elem = ( document.getElementById('nyroModalImg') )? 'nyroModalImg':'img_tmp';

    var w_tmp = $('#'+elem).width();
    var h_tmp = $('#'+elem).height();

    document.getElementById(field).innerHTML = "<div id='loader_tati' style='height:41px;z-index:1;position:relative;top:400px;text-align:center;font-size:16px;color:#CBE423;'><img src='"+path_relative_root+"img/ajaxLoader.gif' alt=\"Chargement en cours...\" width=\"25\" height=\"25\" /><p><strong>Chargement...</strong></p></div>";
    document.getElementById(field).innerHTML += "<div style='position:relative;z-index:2;top:-41px;'><img id='img_tmp' src="+path+"></div>";

    var myImage = new Image();
    myImage.onload = function(){
        myImage.src = this.src;
        $('#' + field).css({height : myImage.height , width : myImage.width});
    };
    // On charge la photo sur laquel on a cliqué dans le bloc de zoom
    myImage.src = path;

    $('#img_tmp').on('load', function() {
        $('#loader_tati').remove();
        $(this).parent().css('top','0');
        $(this).width(w_tmp);
        $(this).height(h_tmp);
        $('img', '#bloc_photo_zoom').css({marginBottom : "-3px"});
    });
}

function closeZoom() {

    var modbox = document.getElementById('zoomBox'),
        shad_zoom = document.getElementById('shad_zoom');

    modbox.style.display = 'none';
    shad_zoom.style.display = 'none';

    delEvent(shad_zoom, 'click', closeZoom);

    closemodal();
}

function updateSrc(path_web,dest) {
    var tmp_string_large = document.getElementById('img_large').src;
    tmp_string = tmp_string_large.replace(/medium/,"large");

    document.getElementById('img_large').setAttribute('onclick','zoomImg(\''+tmp_string+'\',\''+path_web+'\')');
    document.getElementById('loupe').href = 'javascript:zoomImg(\''+tmp_string+'\',\''+path_web+'\');';
    $(".zoomLens").css({backgroundImage : 'url("'+tmp_string_large+'")' });

    if(dest=='vue') {

        var i = 0;
        var trouve = false;
        while (i<tab_js.length && !trouve) {

            trouve = (tab_js[i][0].substring(tab_js[i][0].lastIndexOf('/')+1)==tmp_string.substring(tmp_string.lastIndexOf('/')+1));
            i++;

        }

        cpt_img = (i-1);

    }

}

function defilImg(sens,path_web) {

    if(sens == 'gauche') {
        if(cpt_img!=0) cpt_img--;
        else cpt_img = tab_js.length-1;
    }
    else {
        if(cpt_img<tab_js.length-1) cpt_img++;
        else cpt_img = 0;
    }

    changeVisu('img_large',tab_js[cpt_img][0],tab_js[cpt_img][1]);
    updateSrc(path_web);

}

function prodGetProductDisplay( id, path_web ) {

    var response = ajax_file( path_web + 'ajax_get_infos_send_friend.php?product_id=' +id );
    document.getElementById('sendfriend_prodinfos').innerHTML = response;

    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('6.') != -1 ) {

        var svn=document.getElementsByTagName("SELECT");
        for (a=0;a<svn.length;a++){
            svn[a].style.visibility="hidden";
        }

    }

}

function show_lightbox(lightbox_contact) {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $('#'+lightbox_contact).slideDown("slow",function(){
            $('#visu_popup_ajout_panier',this).recalImg();
        });
        $(this).dequeue();
    }).click(function(){
        close_lightbox(lightbox_contact);
    });
}

function close_lightbox(lightbox_contact) {
    $('#'+lightbox_contact).slideUp("slow").queue(function () {
        $('#shad').fadeTo("slow", 0, function() {
            $(this).css('display','none');
        });
        $(this).dequeue();
    });
}

function changeColorProduct ( id_color, from, prod_id ) {

    var txt_add = '';
    if( from == 'produit' ) {
        txt_add = '_prod';
        id_select = id_color + '_prod';
        var tab = tab_color_border;
    }
    else if( from == 'assoc' ) {
        txt_add = '_assoc_' + prod_id;
        window['id_select_assoc_' + prod_id] = id_color + '_assoc_' + prod_id;
        var tab = eval('tab_color_border_assoc_' + prod_id);
        prod_id = '_' + prod_id;
    }
    else if( from == 'gamme' ) {
        txt_add = '_gamme_' + prod_id;
        window['id_select_gamme_' + prod_id] = id_color + '_gamme_' + prod_id;
        var tab = eval('tab_color_border_gamme_' + prod_id);
        prod_id = '_' + prod_id;
    }

    document.getElementById('couleurProd'+prod_id).value = id_color;

    var cnt_tab = tab.length;

    for(var i=0; i<cnt_tab; i++){

        if(tab[i] == id_color) {
            if( document.getElementById(tab[i]+txt_add) )
                document.getElementById(tab[i]+txt_add).style.border = '1px solid #FD6D0C';
        }else {
            if( document.getElementById(tab[i]+txt_add) )
                document.getElementById(tab[i]+txt_add).style.border = '1px solid #999';
        }
    }

}

function fbs_click( site, params ){

    if ( params == '' ){
        params = location.href;
    }

    switch ( site ){
        case 'fb':
            window.open('http://www.facebook.com/share.php?u='+encodeURIComponent(params)+'&t=');
            _gaq.push(['_trackSocial', 'facebook', 'Share', params]);
        break;
        case 'tw':
            window.open('https://twitter.com/share?url='+params);
            _gaq.push(['_trackSocial', 'twitter', 'Tweet', params]);
        break;
        case 'pin':
            window.open('http://pinterest.com/pin/create/button/?' + params);

            var url = params.split('&');
            url = url[0].split('=');
            url = decodeURIComponent( url[1] );
            _gaq.push(['_trackSocial', 'pinterest', 'Pin', url]);
        break;
    }
    return false;
}

function show_video() {

    var video = $(".wrapper_video");
    var video_content = $(".video_content", video);
    var btn_video_produit = $('.btn_video_produit');

    video.css({display: 'block', opacity: 0}).animate({opacity: 1});

    if (Modernizr.video) {

        video_content[0].play();
    }
}

function stopVideoSpa() {
    var video = $(".wrapper_video");
    var btn_video_produit = $('.btn_video_produit');
    var video_content = $(".video_content", video);
    var videoFlv = $("#video_spa_swf");

    if( Modernizr.video ){
        video_content[0].currentTime = 0;
        video_content[0].pause();
    }
    video.css({display : 'block', opacity : 1}).animate({opacity : 0},function(){
        video.css({display : 'none', opacity : ''});
    });

}

function lightboxAlertStock( id, type_alert ){
    var produit_id = $( '#produit_id' ).val();

    if( !$("#lightbox_stock_alert").is(":visible") )
        var $form = $( '#product_selects form' );
    else
        var $form = $( '#form_colisage' );
    var $input = $( '#' + id, $form ); // ID Champs Clique
    var $from = id.split( '_' )[0];
    var $hidden = $( '#' + type + 'Prod', $form );

    var $select = $( '#ligne_' + type );
    var $list_inputs = $( '.select_input', $select );
    var $list_labels = $( '.select_label', $select );

    var data = $form.serialize();
    data += '&from=nothing';
    data += '&type_alert=' + type_alert;

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_lightbox_alert.php',
        data: data,
        success: function( response ){
            try{
                response = $.parseJSON( response );
            }
            catch( e ){
                console.error( 'parseJSON' );
                return;
            }

            if( response.success == 'ok' ){
                var datas = response.datas;
                var $html = $( datas.html );
                $('#lightbox_stock_alert').html($html);
            }
            else{
                $( '#btn_error' ).fadeIn();
            }
        },
        complete: function(){
            $( '#bloc_btn_loader' ).fadeOut( 300 );
            if( !$("#lightbox_stock_alert").is(":visible") )
                openPopup('lightbox_stock_alert');
            $('.selectStyled').each(function(){
                $(this).selectStyled();
            });
        }
    });
}

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;
function restoreWishlistButtonState(){
    $("#addToWishlistButton").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    $("#addToWishlistButton").attr("onclick",button_add_to_wishlist_initial_click_event).attr("href", "#");
}

function addToWishlist(){
    var product_id = $("#hdn_product_id").val();
    var is_model = $("#hdn_is_model").val();
    if (typeof (button_add_to_wishlist_initial_click_event) == "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }
    if (typeof (button_add_to_wishlist_initial_label) == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }
    if (! product_id || typeof product_id == "undefined") {
        return false;
    }
    var color_id = $("#couleurProd").val();
    var size_id = $("#tailleProd").val();

    var need_color_id = false;
    var need_size_id = false;
    var $shad = $('#shad');
    var res = '';
    var $alertbox = $( '#alertbox1' );
    var $alertboxLogin = $( '#alertboxlogin' );
    var data = {}
    data.product_id = product_id;
    data.size_id = size_id;
    data.color_id = color_id;
    data.need_color_id = need_color_id;
    data.need_size_id = need_size_id;
    data.is_model = is_model;
    has_errors = false;

    if (document.getElementById('lightbox_achat_express')){
        from_range = true;
    } else {
        if( (color_id == "undefined" || color_id != '') && (size_id == "undefined" || size_id != '') ){
            from_range = false;

            var html = '<div class="txt_alert">veuillez choisir une <span id="label_alert"></span></div>';
                    html += '<div class="wrapper_btn"><a onclick="closealerterror();" href="#" class="btn_alert"></a></div>';

            if ($alertbox.length == 0) {
                $alertbox = $('<div id="alertbox1">' + html + '</div>');
            }

            addToWishlistShowLoader();
            $.post(path_relative_root +'ajax_add_to_wishlist.php?time=' + new Date().getTime(), data, function(resp) {
                addToWishlistHideLoader();

                if (resp.error) {
                        if ($alertboxLogin.length == 0) {
                                $alertboxLogin = $('<div id="alertboxlogin">' + html + '</div>');
                        }
                        if (resp.message == "login_required") {

                                if (! product_id || typeof product_id == "undefined") {
                                        openPopup('connexion_wishlist');
                                } else {
                                        openPopup('connexion_wishlist_produit');

                                        parent.frame_nav.navigate({ bot : create_link('connexion_login_wishlist') + '?is_product=true' })
                                }
                        } else if (resp.message != "error") {
                            $alertBox = $alertbox;
                            $alertBox.find("#label_alert").html(resp.message);
                            $alertBox.appendTo( 'body' ).hide().css('z-index','10000');
                            $alertBox.slideDown();
                        } else {
                            return false;
                        }
                            $shad.show().fadeTo("slow", 0.8);
                            addEvent(shad,'click',closeMultiShad);
                } else {
                    $("#addToWishlistButton").html('<span>'+resp.result.button_label+'</span>');
                    $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                    $("#addToWishlistButton").attr("onclick","").attr("href", path_relative_root + create_link('wishlist'));
                    wishlist.push(resp.result.wishlist_row_array);
                    addToWishlistUpdateHeader(resp.result.wishlist_size);
                }
            });
        } else {
            html_err = '<div class="close_pop_ajout_panier" onclick="closeMultiShad();"></div><div class="picto"></div><div class="txt_alert"></div>';
            html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
            if ($alertbox.length == 0) {
                $alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
                $('body').append($alertbox.hide());
            }

            if(size_id != "undefined" && size_id == '') {
                res = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_error_size');
            } else {
                res = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=js_error_couleur');
            }

            $alertbox.find('.txt_alert').html(res);
            openMultiShad('alertbox1', 'fixed');
            return false;
        }
    }
}

function closealertloginerror(){
    $("#alertboxlogin").slideUp(function() {
        $(this).remove();
        $('#shad').fadeOut(function() {
            $(this).remove();
        });
    });
}

function wishlistRedirectLogin(){
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function() {
        document.location.href=path_relative_root + create_link('connexion_login') + "?from=front";
    });
}

function addToWishlistShowLoader(){
    $("#bloc_btn_loader").show();
    $("#addToWishlistButtonContainer").hide();
}

function addToWishlistHideLoader(){
    $("#bloc_btn_loader").hide();
    $("#addToWishlistButtonContainer").show();
}

function addToWishlistUpdateHeader(nr){
    $(".btn_top_wishlist .gris").html('(x' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(x' + nr + ')');
    }
}

var look_add = false;

function addLook() {

    $('#bloc_btn_active').hide();
    $('#bloc_btn_loader').show();

    look_add = true;

    calculTotal('slide_look_detail', true);

}

function hideLoaderLook() {

    $('#bloc_btn_active').show();
    $('#bloc_btn_loader').hide();
    show_lightbox('modbox');

}

function loadProduct( type_filter, id, type_page ){

    if( typeof(id) == 'undefined' ) {
        id_prod = "";
    }
    else {
        id_prod = "_"+id;
    }

    if( typeof(type_page) == 'undefined' ) {
        type_page = 'product';
    }

    var $form = $( '#form_fiche_produit' );
    var $input = $( '#' + type_filter + 'Prod'+id_prod, $form );
    var type = type_filter;
    var $hidden = $( '#' + type_filter + 'Prod'+id_prod, $form );
    var $produit_id_new = $( '#' + type_filter + 'Prod'+id_prod+' :checked').data('produitid');

    var data = $form.serialize();
    data += '&produit_id_new=' + $produit_id_new;
    data += '&type=' + type_filter;
    data += '&type_page=' + type_page;

    if( typeof(id) != 'undefined' ) {

        data += '&couleurProd=' + $( '#couleurProd'+id_prod).val();
        data += '&tailleProd=' + $( '#tailleProd'+id_prod).val();
        data += '&produit_principal=' + $( '#produit_principal'+id_prod).val();
        var produit_principal = $( '#produit_principal'+id_prod).val();

    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function(){
            $( '#bloc_btn_loader' ).show();
            $( '#btn_error' ).fadeOut( 300 );
        },
        success: function( response ){
            try{
                response = $.parseJSON( response );
            }
            catch( e ){
                console.error( 'parseJSON' );
                return;
            }

            if( response.success == 'ok' ){
                var datas = response.datas;

                if( type_page == 'product' ) {

                    var $html_main = $( datas.html_main );
                    var $html_middle = $( datas.html_middle );
                    var $fiche = $( '#wrapper_fiche' );
                    var $form = $( '#wrapper_right_fiche' );
                    var replace_main = new Array(
                        '#ajax_product_description',
                        '#list_caract',
                        '#modbox .infos',
                        '.product_detail_tab_holder',
                        '.content_img_visu_wrapper',
                        '.prix_product',
                        '.product_ref_fiche',
                        '#description_produit',
                        '#reference_fiche_produit',
                        '#visu_popup_ajout_panier',
                        '.restock_date',
                        '#script_zoom',
                        '#jqzoom',
                        '#product_other_views',
                        '#ajax_product_name'
                    );
                    var replace_middle = new Array('#ligne_taille', '#ligne_qte', '#bloc_btn_active', '#bloc_add_alert','#addToWishlistButtonContainer','#bloc_add_basket');

                    replace_main.push( '#social_network' );
                    replace_main.push( '.fond_middle' );

                    for( var i = 0; i < replace_main.length; i++ ){
                        var id = replace_main[i];
                        $( id, $fiche ).html( $( id, $html_main ).html() );
                    }

                    for( var i = 0; i < replace_middle.length; i++ ){
                        var id = replace_middle[i];
                        $( id, $form ).html( $( id, $html_middle ).html() );
                    }

                    $( '#sendfriend_form_buttons', '#sendfriendbox' ).html( $( '#sendfriendbox #sendfriend_form_buttons', $html_main ).html() );

                    $( '#produit_id', '#form_fiche_produit' ).val( $( '#produit_id', $html_middle).val() );
                    $( '#produit_principal', '#form_fiche_produit' ).val( $( '#produit_principal', $html_middle).val() );
                    $( '#produit_lot_nb', '#form_fiche_produit' ).val( $( '#produit_lot_nb', $html_middle).val() );

                    $( '#hdn_product_id', '#form_fiche_produit' ).val( $( '#hdn_product_id', $html_middle).val() );
                    $( '#hdn_is_model', '#form_fiche_produit' ).val( $( '#hdn_is_model', $html_middle).val() );

                    // RECHARGEMENT IMG
                    $(".nbr_visu_content").each(function(){
                        $(".nbr_visu_content").recalImg();
                    });

                    // BLOC DESCRIPTION
                    var heads      = $("[data-tab-head]");
                    var containers = $("[data-tab-container]");
                    var grabber = null;

                    var activateTab = function(event) {
                        event.preventDefault();

                        if (grabber !== null) {

                            grabber = grabber.destroy();
                        }

                        var element        = $(this);
                        var tab_number     = element.attr("data-tab");
                        var head_container = element.parent();
                        var container_name, container, scrollpane;

                        head_container.addClass("current").siblings().removeClass("current");
                        container_name = head_container.parent().attr("data-tab-head");
                        container      = containers.filter("[data-tab-container='" + container_name + "']");
                        scrollpane     = container.children('div').hide().filter('div[data-tab="' + tab_number + '"]').show();

                        grabber = new Grabber(scrollpane.get(0), true, false);
                    };

                    setTimeout(function () {

                        grabber = new Grabber($('.product_detail_tab_holder[data-tab="1"]').get(0), true, false);
                    }, 50);

                    heads.each(function(){
                        var head = $(this);
                        var children = head.children();
                        children.removeClass("current").filter(":first").addClass("current");
                        children.find('[data-tab]').click(activateTab);
                    });

                    containers.each(function(){
                        var container  = $(this);
                        var children   = container.children();
                        var scrollpane = children.eq(0).show().find('.scroll-pane');
                        children.not(":first").hide();

                        if (scrollpane.length) {
                            scrollpane.jScrollPane();
                        }
                    });

                    var slider = document.getElementById("content_img_visu_slider");
                    var bxSliders = document.getElementsByClassName("bxslider2");

                    slider = new Swiper(slider, {
                        direction: "vertical",
                        roundLengths: true,
                        watchOverflow: true,
                        spaceBetween: 15,
                        slidesPerView: 3,
                        navigation: {
                            prevEl: ".bx-prev",
                            nextEl: ".bx-next"
                        }
                    });

                    document.getElementsB

                    Array.prototype.slice.call(bxSliders).forEach(function (bxSlider) {
                        bxSlider = new Swiper(bxSlider);
                    });

                    var string_params = '';
                    if( $("#produit_lot_nb").val() != undefined )
                        string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();

                    $('.restock_date').remove();
                    $('.info_magasin').hide();

                    // RECHARGEMENT QTE
                    var qte = ajax_file(path_relative_root + 'ajax_liste_qte.php?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + datas.product.size.id + string_params);
                    $('#liste_qte').html(qte);

                    $(".eclat_nouveaute").hide();

                    if($('.restock_date').length>0) {
                        $('.info_magasin').append($('.restock_date'));
                        $('.info_magasin').show();
                    }

                    if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                        $("#rassurance_lad").hide();
                    } else {
                        $("#rassurance_lad").show();
                        $("#date_du").html($("#date_du_ajax").val());
                        $("#date_au").html($("#date_au_ajax").val());
                    }

                    if ($('.show_picto_arrivage').length && $('.show_picto_arrivage').val() == '1') {
                        $(".eclat_nouveaute").show();
                    }

                    if ($('.stock_tampon').length && $('.stock_tampon').val() <= 0) {
                        $("#rassurance_lad").hide();
                    }
                    else {
                        $("#rassurance_lad").show();
                    }

                    // Modification de la date d'arrivage à la volée en js car modifié après avoir généré la clé de traduction
                    if ($('.date_ajax').length) {
                        $('.date_ajax').html($('#date_au_ajax').val());
                    }

                    // WISHLIST
                    checkProductInWishlist();
                    $("#addToWishlistButton").click(function(){ addToWishlist(); });

                }
                else if( type_page == 'look' ){

                    var $html_main = $( datas.main_content );
                    $( '#ref_look_'+produit_principal ).html( $('#ref_look_'+produit_principal, $html_main).html() );
                    $( '#look_add_lightbox_'+produit_principal ).html( $('#look_add_lightbox_'+produit_principal, $html_main).html() );

                    calculTotal('slide_look_detail');

                    $('.row_look select').on('change', function() {
                        calculTotal('slide_look_detail');
                        var $val_elem = $(this).val();
                        var $elem = $(this).attr('id');
                        var $id = $elem.substring($elem.lastIndexOf("_")+1);
                        if( $val_elem == 0 ) {
                            $( "#img_popup_" + $id ).parents('.look_item').hide();
                        }
                        else {
                            $( "#img_popup_" + $id ).parents('.look_item').show();
                        }
                    });

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_")+1);
                    var i = array_look.indexOf(parseInt($id_current));
                    if(i != -1) {
                        array_look.splice(i, 1);
                    }

                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function(){
                    $(this).selectStyled();
                });

                Custom.init();
            }else{
                $( '#btn_error' ).fadeIn();
            }
        },
        complete: function(){
            $( '#bloc_btn_loader' ).fadeOut( 300 );
        }
    });
}

function calculTotal( id, process_look ) {

    $form = $('#'+id);
    var data = $form.serialize();

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_calcul_total.php',
        data: data,
        success: function( response ){
            try{
                response = $.parseJSON( response );
            }
            catch( e ){
                console.error( 'parseJSON' );
                return;
            }
            if( response.success == 'ok' ){

                var datas = response.datas;
                var total = datas.total;

                $(".price_total").html(datas.total_format);
                if( typeof(process_look) != 'undefined' ) {

                    if( total > 0 )
                        processlook();
                    else {

                        $('#bloc_btn_active').show();
                        $('#bloc_btn_loader').hide();
                        show_lightbox('modboxerr');

                    }

                }

            }

        }

    });

}

function processlook(){
    $('.add_look_product div').each(function() {
        var $elem = $(this).attr('id');
        var $id = $elem.substring($elem.lastIndexOf("_")+1);
        var $val_item = $("#qteProd_"+$id).val();
        if ($val_item > 0) {
            $(this).click();
        }
    });

    setTimeout("hideLoaderLook()",5000);

}

function checkProductInWishlist() {
    var prod_id = $("#produit_id").val();
    var couleurProd = $("#couleurProd");
    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else {
        couleurProd = 0;
    }
    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else {
        tailleProd = 0;
    }

    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link('get_user_wishlist'),
            data:{'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    if (in_wishlist) {
        $("#addToWishlistButton").attr("onclick", "");
        $("#addToWishlistButton").attr("href", path_relative_root + create_link('wishlist'));
        $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
    } else {
        $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event);
        $("#addToWishlistButton").attr("href", "javascript:;");
        $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}