(function () {

    var key;

    function Frame_Nav() {

        this.top = window.document.getElementById('topFrame');
        this.bot = window.document.getElementById('botFrame');
    }

    Frame_Nav.prototype.navigate = function (parm) {

        for (key in parm) {

            if (key === 'top' || key === 'bot') {

                if (parm[key] === 'refresh') {

                    this[key].contentWindow.location.reload();
                } else {

                    if ((parm[key]).indexOf(path_relative_root) === -1) {

                        parm[key] = path_relative_root + parm[key];
                    }

                    window.open(parm[key], this[key].name);
                }
            }
        }
    };

    window.Frame_Nav = Frame_Nav;
}());
