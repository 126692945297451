var ccErrorNo = 0;
var ccErrors = new Array ()

ccErrors [0] = "Type de carte Inconnu";
ccErrors [1] = "Aucun num?o de carte n'a ??saisi.\nMerci de renseigner votre num?o de carte bancaire.";
ccErrors [2] = "Le format de votre num?o de carte est invalide";
ccErrors [3] = "Le num?o de votre carte est invalide";
ccErrors [4] = "Le num?o de votre carte n'a pas la bonne longueur";
ccErrors [5] = "Le Code de v?ification ou le cryptogramme visuel de votre carte est invalide";
ccErrors [6] = "Aucun nom n'a ??saisi.\nMerci de renseigner votre nom";
ccErrors [7] = "La date d'expiration de votre carte est incorrecte";

function checkCreditCard (cardnumber, cardname, cvc, titulaire, mois, annee, ignore_card ) {

// Array to hold the permitted card characteristics
var cards = new Array();
var prefix = new Array ();
  // Define the cards we support. You may add addtional card types.


  //  Name:      As in the selection box of the form - must be same as user's
  //  Length:    List of possible valid lengths of the card number for the card
  //  prefixes:  List of possible prefixes for the card
  //  checkdigit Boolean to say whether there is a check digit

  cards [0] = {name: "Visa",
			   length: "13,16",
			   prefixes: "4",
			   checkdigit: true,
			   cvclength: 3};
  cards [1] = {name: "MasterCard",
			   length: "16",
			   prefixes: "51,52,53,54,55",
			   checkdigit: true,
			   cvclength: 3};
  cards [2] = {name: "Maestro",
			   length: "12,13,14,15,16,17,18,19,20",
			   prefixes: "5018,5020,5033,5036,5038,5452,5612,5817,5868,5892,6304,6703,6759,6761",
			   checkdigit: true,
			   cvclength: 0};
  var cardType = -1;
  var cardnumberpre = cardnumber.replace (/\s/g, "");
  cardnumberpre = cardnumberpre.replace (/ /g, "");
  cardnumberpre = cardnumberpre.replace (/-/g, "");
  if( cardname != '' ) {
	  // Establish card type


	  for (var i=0; i<cards.length; i++) {


			// See if it is this card (ignoring the case of the string)
			if (cardname.toLowerCase () == cards[i].name.toLowerCase()) {
			  cardType = i;
			  break;
			} else if ( cardname.toLowerCase() == 'cb' ) {
				prefix = cards[i].prefixes.split(",");

			  // Now see if any of them match what we have in the card number
			  for (j=0; j<prefix.length; j++) {
				var exp = new RegExp ("^" + prefix[j]);
				if (exp.test (cardnumberpre)) {
					cardType = i;
					break;
				}
			  }
			}
	   }
   }
   else {

		if( cardnumberpre.substring(0,1) == '4' ) {
			// VISA
			cardType = 0;
		}
		else if( cardnumberpre.substring(0,2) == '51' || cardnumberpre.substring(0,2) == '52' || cardnumberpre.substring(0,2) == '53' || cardnumberpre.substring(0,2) == '54' || cardnumberpre.substring(0,2) == '55' ) {
			// MASTERCARD
			cardType = 1;
		}
		else if( cardnumberpre.substring(0,4) == '5018' || cardnumberpre.substring(0,4) == '5020' || cardnumberpre.substring(0,4) == '6703' || cardnumberpre.substring(0,4) == '5033' || cardnumberpre.substring(0,4) == '5038' || cardnumberpre.substring(0,4) == '5817' || cardnumberpre.substring(0,4) == '5868' || cardnumberpre.substring(0,4) == '6304' || cardnumberpre.substring(0,4) == '6759' || cardnumberpre.substring(0,4) == '6761' || cardnumberpre.substring(0,4) == '5036' || cardnumberpre.substring(0,4) == '5452' || cardnumberpre.substring(0,4) == '5612' || cardnumberpre.substring(0,4) == '5868' || cardnumberpre.substring(0,4) == '5892' ) {
			// MAESTRO
			cardType = 2;
		}

	}

  if ( titulaire.length < 3 ) {
	ccErrorNo = 6;
    return false;
  }

  // Ensure that the user has provided a credit card number
  if (cardnumberpre.length == 0)  {
     ccErrorNo = 1;
     return false;
  }

  // If card type not found, report an error
  if (cardType == -1 && !ignore_card) {
	 ccErrorNo = 0;
	 return false;
  }

  // Now remove any spaces from the credit card number
  cardnumber = cardnumber.replace (/\s/g, "");
  cardnumber = cardnumber.replace (/ /g, "");
  cardnumber = cardnumber.replace (/-/g, "");

  // Check that the number is numeric
  var cardNo = cardnumber
  var cardexp = new RegExp("[0-9]{12,20}","g");

  if (!cardexp.test(cardNo) && !ignore_card)  {
     ccErrorNo = 2;
     return false;
  }


  if( !ignore_card ) {
	  // Now check the modulus 10 check digit - if required
	  if (cards[cardType].checkdigit && !ignore_card) {
		var checksum = 0;                                  // running checksum total
		var mychar = "";                                   // next char to process
		var j = 1;                                         // takes value of 1 or 2

		// Process each digit one by one starting at the right
		var calc;
		for (i = cardNo.length - 1; i >= 0; i--) {

		  // Extract the next digit and multiply by 1 or 2 on alternative digits.
		  calc = Number(cardNo.charAt(i)) * j;

		  // If the result is in two digits add 1 to the checksum total
		  if (calc > 9) {
			checksum = checksum + 1;
			calc = calc - 10;
		  }

		  // Add the units element to the checksum total
		  checksum = checksum + calc;

		  // Switch the value of j
		  if (j ==1) {j = 2} else {j = 1};
		}

		// All done - if checksum is divisible by 10, it is a valid modulus 10.
		// If not, report an error.
		if (checksum % 10 != 0)  {
		 ccErrorNo = 3;
		 return false;
		}
	  }


	  // The following are the card-specific checks we undertake.
	  var LengthValid = false;
	  var PrefixValid = false;
	  var undefined;

	  // We use these for holding the valid lengths and prefixes of a card type
	  var lengths = new Array ();

	  // Load an array with the valid prefixes for this card
	  prefix = cards[cardType].prefixes.split(",");

	  // Now see if any of them match what we have in the card number
	  for (i=0; i<prefix.length; i++) {
		var exp = new RegExp ("^" + prefix[i]);
		if (exp.test (cardNo)) PrefixValid = true;
	  }

	  // If it isn't a valid prefix there's no point at looking at the length
	  if (!PrefixValid && !ignore_card) {
		 ccErrorNo = 3;
		 return false;
	  }

	  // See if the length is valid for this card
	  lengths = cards[cardType].length.split(",");
	  for (j=0; j<lengths.length; j++) {
		if (cardNo.length == lengths[j]) LengthValid = true;
	  }

	  // See if all is OK by seeing if the length was valid. We only check the
	  // length if all else was hunky dory.
	  if (!LengthValid && !ignore_card) {
		 ccErrorNo = 4;
		 return false;
	  };
	  var cvc_match = cvc.match(/^[0-9]+$/);
	  if ( cards[cardType].cvclength > 0 && (cvc.length != cards[cardType].cvclength || !cvc_match ) ) {
		ccErrorNo = 5;
		return false;
	  }

	}
	else {

		var cvc_match = cvc.match(/^[0-9]+$/);
	    // Pour le moment on teste la longueur que sur 3
		if ( cvc.length != 3 || !cvc_match ) {
		  ccErrorNo = 5;
		  return false;
	    }

	}

  if ( !mois.match(/^[0-9]{1,2}$/) || !annee.match(/^20[0-9]{2}$/) ) {
	ccErrorNo = 7;
    return false;
  }

  if ( mois.substring( 0,1) == '0' )
	mois = mois.substring( 1 );

  mois = parseInt( mois );
  annee = parseInt( annee );

  var date = new Date();

  var current_month = date.getMonth();
  current_month++;

  var current_year = date.getFullYear();

  if ( annee < current_year || (annee == current_year && mois < current_month ) ) {
	ccErrorNo = 7;
    return false;
  }

  return true;
}


var last_err_input = null;

function checkBank(acompte) {

	var ignore_card = false;

	var card_number = document.getElementById('numero_first').value+document.getElementById('numero_second').value+document.getElementById('numero_third').value+document.getElementById('numero_fourth').value;

	if (acompte) {

		var card_type = document.getElementById('acompte_type_carte').value;
		var card_number = document.getElementById('acompte_numero').value;
		var cvc = document.getElementById('acompte_cvc').value;
		var name = document.getElementById('acompte_name').value;
		var date_exp_mois = document.getElementById('acompte_date_exp_mois').value;
		var date_exp_annee = document.getElementById('acompte_date_exp_annee').value;

		if ( document.getElementById('acompte_do_alias').checked && card_number.match(/X/) ) ignore_card = true;

	} else {

		var card_type = document.getElementById('type_carte').value;
		var card_number = document.getElementById('numero').value;
		var cvc = document.getElementById('cvc').value;
		var name = document.getElementById('name').value;
		var date_exp_mois = document.getElementById('date_exp_mois').value;
		var date_exp_annee = document.getElementById('date_exp_annee').value;

		if ( document.getElementById('do_alias').checked && card_number.match(/X/) ) ignore_card = true;
	}

	var check_bank = checkCreditCard( card_number, card_type, cvc, name, date_exp_mois, date_exp_annee, ignore_card );

	if ( last_err_input != null )  {

		var objElement = $("#"+last_err_input);
		var objLabel = $("#"+last_err_input + "Label");

		if ( objElement )
			objElement.removeClass('inputErr');
		if ( objLabel )
			objLabel.removeClass('labelErr');

		last_err_input = null;

	}

	if( !check_bank ) {

		if ( ccErrorNo == 0 )
			last_err_input = 'type_carte';
		else if ( ccErrorNo == 1 || ccErrorNo == 2 || ccErrorNo == 3 || ccErrorNo == 4 )
			last_err_input = 'num_carte';
		else if ( ccErrorNo == 5 )
			last_err_input = 'cvc_carte';//cvc
		else if ( ccErrorNo == 6 )
			last_err_input = 'titulaire_carte';//titulaire
		else if ( ccErrorNo == 7 )
			last_err_input = 'month_carte';//date exp

		var objElement = $("#"+last_err_input);
		var objLabel = $("#"+last_err_input + "Label");

		if ( objElement )
			objElement.addClass('inputErr');
		if ( objLabel )
			objLabel.addClass('labelErr');

		alert('Erreur :\n'+ccErrors[ccErrorNo]);
	}

	return check_bank;

}

var block_redirection = false;

function checkBankWithoutCard( redirect, acompte ) {

	var btn = $('#form_without_choix_carte #bt_valider');

	$('.loader', btn).show();
	$('input', btn).hide();

	var ignore_card = false;

	if ( acompte ) {

		var card_number = document.getElementById('acompte_numero_without_card').value;
		var cvc = document.getElementById('acompte_cvc').value;
		var name = document.getElementById('acompte_name').value;
		var date_exp_mois = document.getElementById('acompte_date_exp_mois').value;
		var date_exp_annee = document.getElementById('acompte_date_exp_annee').value;

		if ( document.getElementById('acompte_do_alias') && document.getElementById('acompte_do_alias').checked && card_number.match(/X/) ) ignore_card = true;

	} else {

		var card_number = document.getElementById('numero_without_card').value;
		var cvc = document.getElementById('cvc').value;
		var name = document.getElementById('titulaire_carte').value;
		var date_exp_mois = document.getElementById('date_exp_mois').value;
		var date_exp_annee = document.getElementById('date_exp_annee').value;

		if ( document.getElementById('do_alias') && document.getElementById('do_alias').checked && card_number.match(/X/) ) ignore_card = true;
	}

	var check_bank = checkCreditCard (card_number, '', cvc, name, date_exp_mois, date_exp_annee,ignore_card);

	if ( last_err_input != null )  {
		var objElement = $("#"+last_err_input);
		var objLabel = $("#"+last_err_input + "Label");

		if ( objElement )
			objElement.removeClass('inputErr');
		if ( objLabel )
			objLabel.removeClass('labelErr');

		last_err_input = null;
	}

	if( !check_bank ) {
		if ( ccErrorNo == 1 || ccErrorNo == 2 || ccErrorNo == 3 || ccErrorNo == 4 )
			last_err_input = 'numero_without_card';
		else if ( ccErrorNo == 5 )
			last_err_input = 'cvc';//cvc
		else if ( ccErrorNo == 6 )
			last_err_input = 'titulaire_carte';//titulaire
		else if ( ccErrorNo == 7 )
			last_err_input = 'month_carte';//date exp

		var objElement = $("#"+last_err_input);
		var objLabel = $("#"+last_err_input + "Label");

		if ( objElement )
			objElement.addClass('inputErr');
		if ( objLabel )
			objLabel.addClass('labelErr');

		$('.loader', btn).hide();
		$('input', btn).show();
	} else {
		if ( document.getElementById('btn_without_card') && document.getElementById('form_without_choix_carte') )
			document.getElementById('form_without_choix_carte').removeChild(document.getElementById('btn_without_card'));
		if ( block_redirection && check_bank )
			check_bank = false;

		block_redirection = true;
	}

	if ( redirect && check_bank ) {

		document.getElementById('form_without_choix_carte').submit();
	}

	return check_bank;
}

function chg_valid() {
	document.getElementById('bt_valider').style.fontFamily='Helvetica';
	document.getElementById('bt_valider').style.fontSize='12px';
	document.getElementById('bt_valider').innerHTML='Traitement en cours...';
}

function setBankFormAction( path_to_send_banque, path_to_ogone) {

	if ( document.getElementById( 'do_alias' ) && document.getElementById( 'do_alias' ).checked && !document.getElementById('numero_without_card').value.match(/X/) )
		document.getElementById('form_without_choix_carte').action = path_to_ogone;
	else
		document.getElementById('form_without_choix_carte').action = path_to_send_banque;

}

function changeCarte( nom, num, mois, annee, id ) {

	var checkbox_checked = true;

	if( typeof(nom) === 'undefined' ) {

		id = 0;
		nom = '';
		num = '';
		var ladate = new Date();
		mois = '01';
		annee = ladate.getFullYear();
		checkbox_checked = false;

		$("#name_without_card").focus();

	}

	$("#alias_id").val(id);
	$("#name_without_card").val(nom);
	$("#numero_without_card").val(num);
	$("#date_exp_mois").val(mois);
	$("#date_exp_mois").closest(".selectStyled").find(".clone").html(mois);
	$("#date_exp_annee").val(annee);
	$("#date_exp_annee").closest(".selectStyled").find(".clone").html(annee);

	$("#bloc_other_alias").slideUp();
	is_deplie = false;
	document.getElementById('do_alias').checked=checkbox_checked;

}

var is_deplie = false;

function deleteAlias( path_relative_root, alias_id ) {

	var response = ajax_file( path_relative_root + 'ajax_delete_alias.php?id=' + alias_id);

	if ( response == '1' ) {
		$('#ligne_alias_' + alias_id).fadeOut(500, function() { $('#ligne_alias_' + alias_id).remove(); });

		if ( $("#alias_id").val() == alias_id )
			changeCarte();
	}
}
