function checkdate (d, m, y) {
    return m > 0 && m < 13 && y > 0 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}
var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();
if ((nav_name.indexOf('safari') !== -1 && nav_ver <= 525 ) || (nav_name.indexOf('firefox') !== -1 && nav_ver === 1.8)) {
    function _dom_trackActiveElement(evt) {
        if (evt && evt.target) {
            document.activeElement = evt.target == document ? null : evt.target;
        }
    }
    function _dom_trackActiveElementLost(evt) {
        document.activeElement = null;
    }
    if (!document.activeElement) {
        document.addEventListener("focus",_dom_trackActiveElement,true);
        document.addEventListener("blur",_dom_trackActiveElementLost,true);
    }
}
function ErreurMsg(idObj, msg) {
 var message =  document.getElementById(idObj);
 message.style.color = 'red';
 message.innerHTML = msg;
}
function setInitialClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');
        if ( elementInput && elementInput.type != 'hidden' ) {
            elementInput.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}
function setInitialDateClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInputDay        = document.getElementById(elementIdArray[i] + 'J');
        var elementInputMonth    = document.getElementById(elementIdArray[i] + 'M');
        var elementInputYear    = document.getElementById(elementIdArray[i] + 'A');
        var elementLabel        = document.getElementById(elementIdArray[i] + 'Label');
        if ( elementInputDay && elementInputDay.type != 'hidden'
            && elementInputMonth && elementInputMonth.type != 'hidden'
            && elementInputYear && elementInputYear.type != 'hidden') {
            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}
function verif_radio(elementID, fieldname) {
    if( elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle' )
        elementID = 'clientCivilite';
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (elementID) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
         $("input[name='"+elementID+"']").each(function(idx) {
            if ($(this).is(':checked') == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                trouve = true;
            }
        });
        if( !trouve ) {
            if( elementID == 'clientCivilite' )
            {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            }
            else
            {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    return true;
}
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length ; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}
function verif_checkbox_index( elementName, fieldname, pIndex ) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if ( document.prodForm.visuStatutDetail1 ){
        if ( document.prodForm.visuStatutDetail1[0].checked == true ){
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }else{
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}
function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var flg = 0;
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var idPays = objZipElement.value.substr(0,2);
    if( ( objCountryElement.value == "DO" && idPays != '97' ) || ( ( objCountryElement.value == "TO" || objCountryElement.value == "MO" ) && idPays != '98' ) ) {
        if( objCountryElement.value == "DO" ) idPays = 97;
        else idPays = 98;
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=' + idPays);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objZipElement).removeClass('inputForm').addClass('inputErr');
        $(objLabel).removeClass('labelForm').addClass('labelErr');
        return false;
    }
    else if( ( objCountryElement.value != "DO" && idPays == '97' ) || ( ( objCountryElement.value != "TO" && objCountryElement.value != "MO" && objCountryElement.value != "FR" ) && idPays == '98' ) ) {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objZipElement).removeClass('inputForm').addClass('inputErr');
        $(objLabel).removeClass('labelForm').addClass('labelErr');
        return false;
    }else if (idPays == '20' && objCountryElement.value == "FR"){ // ADRESSE EN CORSE NON ACCEPTE
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_zip_special_3');
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objZipElement).removeClass('inputForm').addClass('inputErr');
        $(objLabel).removeClass('labelForm').addClass('labelErr');
        return false;
    }else if(objCountryElement.value == "AD"){
        var nb = 5;
        var point=0;
        var virgule=0;
        var num = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        if (objZipElement.value=="") {
            $(objElement).data('data-error-text', t_error);
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
            erreurText.innerHTML = t_error;
            $(objZipElement).removeClass('inputForm').addClass('inputErr');
            $(objLabel).removeClass('labelForm').addClass('labelErr');
            return false;
        }else {
            if (objZipElement.value.length < nb) {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
                $(objElement).data('data-error-text', t_error);
                erreurText.innerHTML = t_error;
                $(objZipElement).removeClass('inputForm').addClass('inputErr');
                $(objLabel).removeClass('labelForm').addClass('labelErr');
                return false;
            }
            for (var k = 0; k < objZipElement.value.length; k++) {
                var test = objZipElement.value.substring(k, k + 1);
                if (num.indexOf(test) < 0)
                    flg++;
            }
            if (flg > 0) {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
                $(objElement).data('data-error-text', t_error);
                erreurText.innerHTML = t_error;
                $(objZipElement).removeClass('inputForm').addClass('inputErr');
                $(objLabel).removeClass('labelForm').addClass('labelErr');
                flg = 0;
                return false;
            }
            for (var k = 0; k < objZipElement.value.length; k++) {
                var test = objZipElement.value.substring(k, k + 1);
                if (test == '.'){
                    point=point+1;
                }
                if (test == ','){
                    virgule=virgule+1;
                }
            }
            var tot=point + virgule;
            if (point >1 || virgule>1 || tot>1) {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
                $(objElement).data('data-error-text', t_error);
                erreurText.innerHTML = t_error;
                    $(objZipElement).removeClass('inputForm').addClass('inputErr');
                $(objLabel).removeClass('labelForm').addClass('labelErr');
                flg = 0;
                return false;
            }
        }
    }
    $(objZipElement).removeClass('inputErr').addClass('inputForm');
    $(objLabel).removeClass('labelErr').addClass('labelForm');
    return true;
}
function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point=0;
    var virgule=0;
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value=="") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        $(objElement).removeClass('inputForm').addClass('inputErr');
        $(objLabel).removeClass('labelForm').addClass('labelErr');
        return false;
    }
    else {
        if (objElement.value.length < nb) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).removeClass('inputForm').addClass('inputErr');
            $(objLabel).removeClass('labelForm').addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).removeClass('inputForm').addClass('inputErr');
            $(objLabel).removeClass('labelForm').addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.'){
                point=point+1;
            }
            if (test == ','){
                virgule=virgule+1;
            }
        }
        var tot=point + virgule;
        if (point >1 || virgule>1 || tot>1) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            $(objElement).removeClass('inputForm').addClass('inputErr');
            $(objLabel).removeClass('labelForm').addClass('labelErr');
            flg = 0;
            return false;
        }
    }
    $(objElement).removeClass('inputErr').addClass('inputForm');
    $(objLabel).removeClass('labelErr').addClass('labelForm');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID+'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if( objElementHidden !== null ){

        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");
        
        if( $(objElement).intlTelInput("isValidNumber") !== false && (type === 1 || type === 2)){
            $('#mobileHidden').val(value);
        } else if ($(objElement).intlTelInput("isValidNumber") !== false) {
            $(objElementHidden).val(value);
        }

        if($(objElement).intlTelInput("isValidNumber") === false){
            return false;
        }

        return true;
    }

    if ($.trim($(objElement).val()).length > 0) {
		if ($(objElement).intlTelInput("isValidNumber") === false || ( (is_mobile && $(objElement).intlTelInput("getNumberType") === 0 ) ) || (is_mobile == false && $(objElement).intlTelInput("getNumberType") === 1 ) )
        {
            return false;
        } else {
            $(objElementHidden).val($(objElement).intlTelInput("getNumber"));
        }

        return true;
    }

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value=="")
    {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length <9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname );
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);

            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname );
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg=0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value=="")
    {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length <4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);

            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname );
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg=0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_empty( elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value=="")
    {
        t_error =  '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum="�0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; �����������������&�����������������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value=="")
    {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        if ( isMin == false && objElement.value.length <3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname );
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);

            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            flg=0;
            return false;
        }
        if( isMin == true && objElement.value.length > length ) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; ������i��������&�������I��������?!�-";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value=="")
    {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if( objLabel )
            objLabel.className = 'labelErr';
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if( objLabel )
                objLabel.className = 'labelErr';
            return false;
        }
        if ( isMin == false && objElement.value.length <3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname );
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if( objLabel )
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);

            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if( objLabel )
                objLabel.className = 'labelErr';
            flg=0;
            return false;
        }
        if( isMin == true && objElement.value.length > length ) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path_relative_root +'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if( objLabel )
        objLabel.className = 'labelForm';
    return true;
}
function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum="0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; �����i�������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value=="")
    {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length <3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);
            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname );
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg=0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}
function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value=="")
    {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length <3)
    {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}
function verif_mail(elementID, fieldname){
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');
        $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    var mail = new RegExp("^[a-z0-9._-]+@[a-z0-9.-]{2,}[.][a-z]{2,3}$","gi");
    path = '';
    if( typeof(path_relative_root) != 'undefined' ){
        path += path_relative_root;
    }
    if (mail.exec(objElement.value)==null){
        t_error = '<p class="title_toolltip">' + ajax_file(path+'ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file(path+'ajax_sprintf.php?arg1=js_wrong_input_mail');
            $(objElement).attr('data-error-text', t_error);
            erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        return false;
    }
    objElement.className = 'inputForm';
    return true;
}
function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum="abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/. �����i����������������I��������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value=="")
    {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    else
    {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <=2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        if (objElement.value.length <2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname );
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        for (var k=0;k<objElement.value.length;k++){
            var test = objElement.value.substring(k,k+1);
            if (alphanum.indexOf(test)<0)
                flg++;
        }
        if (flg>0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            flg=0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_format_date(elementID, fieldname) {
    var objElement    = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente= new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_format_date2(elementID, fieldname) {
    var objElement    = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente= new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_format_date_select(elementID, fieldname) {
    var objElementJ    = document.getElementById(elementID+'J');
    var objElementM    = document.getElementById(elementID+'M');
    var objElementA    = document.getElementById(elementID+'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_format_majorite(elementID, fieldname) {
    var objElementJ    = document.getElementById(elementID+'J');
    var objElementM    = document.getElementById(elementID+'M');
    var objElementA    = document.getElementById(elementID+'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee-18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        else{
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}
function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {
        if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
            objLabel.className = 'labelErr';
            return false;
        }
        else{
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}
function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        else{
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    else{
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}
function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau){
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false
            && objForm.prodCateg_0.selectedIndex
            && objForm.prodSousCateg_0.selectedIndex
            && !objForm.prodObjet_0.selectedIndex ) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    }else{
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for( x=0; x<=4; x++ ){
            if (eval("objForm.prodCateg_" + x + ".selectedIndex") )
                if(!eval('objForm.prodObjet_' + x ))
                    $errTMP = true;
                else
                    if(!eval('objForm.prodObjet_' + x + '.selectedIndex') )
                        $errTMP = true;
        }
        if ($errTMP==true){
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}
var indexImg = 0;
function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length)
            indexImg = 0;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}
function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0 )
            indexImg = imgPath.length - 1;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}
function NewWindow(mypage,myname,w,h,scroll){
LeftPosition = (screen.width) ? (screen.width-w)/2 : 0;
TopPosition = (screen.height) ? (screen.height-h)/2 : 0;
settings =
'height='+h+',width='+w+',top='+TopPosition+',left='+LeftPosition+',scrollbars='+scroll+',resizable,';
win = window.open(mypage,myname,settings);
}
function showPanel(panelID, panelIdTab) {
    var panel = document.getElementById(panelID);
    var panelButton = document.getElementById(panelID + 'Button');
    for (i = 0; i < panelIdTab.length; i++) {
        document.getElementById(panelIdTab[i]).style.display = 'none';
        var panBtn = document.getElementById(panelIdTab[i] + 'Button');
        if (panelIdTab[i] != panelID) { panBtn.src = panBtn.src.split('gif')[0].slice(0, -4) + '.gif'; }
    }
    panel.style.display = 'block';
    panelButton.src = panelButton.src.split('gif')[0].slice(0, -1) + '_on.gif';
}
function showEmail(nomID, prenomID){
    var pattern_accent =         new Array("�", "�", "�", "�", "�", "�", "�", "�", "�", "�", "�", "�", "�", "�", " ", "!", "%", "\'", "\"");
    var pattern_replace_accent = new Array("e", "e", "e", "e", "c", "a", "a", "a", "i", "i", "u", "o", "o", "o", "-", "", "", "","");
    var nom = document.getElementById(nomID).value.toLowerCase();
    nom = preg_replace (pattern_accent, pattern_replace_accent, nom);
    var prenom = document.getElementById(prenomID).value.toLowerCase();
    prenom = preg_replace (pattern_accent, pattern_replace_accent, prenom);
    var lettre = prenom.substring(0,1);
    var mail = lettre+''+nom;
    return mail;
}
function preg_replace (array_pattern, array_pattern_replace, my_string) {
    var new_string = String (my_string);
    for (i=0; i<array_pattern.length; i++) {
        var reg_exp= RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace (reg_exp, val_to_replace);
        }
    return new_string;
}
function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point=0;
    var virgule=0;
    if (objElement.value=="") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    }
    else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point=0;
    var virgule=0;
    if (objElement.value=="") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    }
    else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb +'&arg3=' + fieldname );
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function verifSame( field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if ( document.getElementById(field1).value != document.getElementById(field2).value ) {
        var txt_lang = ( fieldname == 'login_email_verif' )? 'js_same_email_confirm':'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1='+fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1='+txt_lang+'&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}
function isClosedDay( day, month ) {
    var array_closed_array = new Array( '1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12' );
    return array_closed_array.in_array( day + '/' + month );
}
Array.prototype.in_array = function(p_val) {
    for(var i = 0, l = this.length; i < l; i++) {
        if(this[i] == p_val) {
            return true;
        }
    }
    return false;
};
function changeVisu(field,path)
{
    $('#'+field).attr('src', '');
    $('#'+field).attr('src', path);
}
function getPos (obj) {
    var pos = {x: obj.offsetLeft||0, y: obj.offsetTop||0};
        while(obj = obj.offsetParent) {
            pos.x += obj.offsetLeft||0;
            pos.y += obj.offsetTop||0;
        }
    return pos;
}
function closeInfobulle( id ) {
    if(!jQuery.browser.msie) $("#"+id).fadeOut(400);
    else document.getElementById(id).style.display = 'none';
    document.getElementById(id).style.zIndex = '0';
}
var one_time = false;
function openInfobulle( id, type, elem ) {
    window.clearTimeout(timer);
    $("#bloc_erreur").css('display','none');
    if(!jQuery.browser.msie) $("#bloc_erreur").stop(false,true).fadeOut();
    if(!jQuery.browser.msie) $("#"+id).fadeIn(400);
    else document.getElementById(id).style.display = 'block';
    if( typeof(type) != 'undefined' ) {
        var margin_spe_left = 0;
        var margin_spe_top = 0;
        if( elem.nodeName == 'SELECT' ) {
            id = elem.id.substr(0,elem.id.length-1);
            if( id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost' ) {
                var txt_spe = document.getElementById( id + 'ErrErr' ).innerHTML;
                margin_spe_left = 300;
                if( id == 'clientPwdAuth' )
                    margin_spe_top = 10;
                infos_alias
            }
            else if ( type == 'banque' )
                var txt_spe = document.getElementById( 'infos_carte' ).innerHTML;
            else if( type == 'alias_banque' )
                var txt_spe = document.getElementById( 'infos_alias' ).innerHTML;
            else
                var txt_spe = document.getElementById( id + 'Err' ).innerHTML;
        }
        else {
            if( elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost' ) {
                if( document.getElementById( elem.id + 'ErrErr' ) )
                    var txt_spe = document.getElementById( elem.id + 'ErrErr' ).innerHTML;
                if( elem.id == 'mailLost' )
                    margin_spe_left = -350;
                else
                    margin_spe_left = -340;
                if( elem.id == 'clientPwdAuth' )
                    margin_spe_top = 41;
                else
                    margin_spe_top = 4;
            }
            else if ( type == 'banque' )
                var txt_spe = document.getElementById( 'infos_carte' ).innerHTML;
            else if( type == 'alias_banque' )
                var txt_spe = document.getElementById( 'infos_alias' ).innerHTML;
            else
                var txt_spe = document.getElementById( elem.id + 'Err' ).innerHTML;
        }
        document.getElementById('erreur_msg').innerHTML = txt_spe;
        pos = getPos(elem);
        document.getElementById('bloc_erreur').style.position = 'absolute';
        if ( type == 'banque' ){
            margin_spe_left -= 42;
            margin_spe_top += 30;
        }
        else if( type == 'alias_banque' ) {
            margin_spe_left -= 42;
            margin_spe_top += 15;
        }
            document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';
            pos_left_margin = getPos(document.getElementById('content'));
            left_add = 0;
            if( document.getElementById('wrapper_anniversaire_jour1' ) ) {
                left_add = document.getElementById('bloc_erreur').offsetWidth;
                left_add -= 35;
            }
            document.getElementById('bloc_erreur').style.left = (pos.x+(elem.offsetWidth-8) + margin_spe_left + 27 - left_add) + 'px';
            if ( type == 'banque' || type == 'alias_banque' ) {
                document.getElementById('bloc_erreur').style.width='280px';
            } else {
                document.getElementById('bloc_erreur').style.width='280px';
            }
        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}
function OverPopup( elem, msg, type, is_vp ) {
    clearTimeout(timer);
    if( type == 'visible' ) {
        if( typeof(is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            $("#bloc_erreur_vp").css('display','none');
            if(!jQuery.browser.msie) {
                $("#bloc_erreur_vp").stop(false,true).fadeOut();
            }
            if(!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeIn(400);
            }
            else {
                document.getElementById('bloc_erreur_vp').style.display = 'block';
            }
        }else {
            if( !document.getElementById('formAnniversaire') || ( document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit' ) ) {
                pos = getPos(elem);
                document.getElementById('erreur_msg').innerHTML = msg;
                if(!jQuery.browser.msie) {
                    $("#bloc_erreur").stop(true, true).fadeIn(400);
                }
                else {
                    document.getElementById('bloc_erreur').style.display = 'block';
                }
                document.getElementById('bloc_erreur').style.position = 'absolute';
                var topHeight = $('#main_wrapper #top_wrapper_concours, #top_wrapper,#main_wrapper_home #top_wrapper_concours, #top_wrapper, #popup_inscription_home').css('top');
                var spltTopHeight = topHeight.split('px');
                var rsltTopHeight = Number(spltTopHeight[0]);
                if( rsltTopHeight == 0 )
                    rsltTopHeight = 4;
                pos_left_margin = -13;
                var left_add = 17;
                if( document.getElementById('wrapper_anniversaire_jour1' ) ) {
                    left_add = document.getElementById('bloc_erreur').offsetWidth;
                    left_add -= 35;
                }
                if( document.getElementById('formAnniversaire') ) {
                    left_add = $('#erreur_msg').width()-10;
                    $(".bottom_tooltip").attr('align','right');
                }
                document.getElementById('bloc_erreur').style.left = (pos.x+(elem.offsetWidth-8) - pos_left_margin - left_add)+ 1 + 'px';
                if (is_vp === 'cmpid_adwords') {
                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top : $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left : $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };
                    $('#bloc_erreur').remove();
                    $(elem).after(clone);
                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        'float' : 'right',
                        'margin' : '0 7px'
                    });
                    $('#bloc_erreur').css({
                        'position' : 'absolute',
                        'top' : c_pos.top,
                        'left' : c_pos.left,
                        'z-index' : '99999',
                        'opacity' : '1'
                    });
                } else {
                    document.getElementById('bloc_erreur').style.top = (pos.y - (document.getElementById('bloc_erreur').offsetHeight + rsltTopHeight)) + 'px';
                }
                if(elem.id=="departement_naissance"){
                    $('#bloc_erreur').css({"width":180,"marginTop":63,"marginLeft":-310});
                }
                document.getElementById('bloc_erreur').style.zIndex = '99999';
            }
        }
    }else {
        if( typeof(is_vp) != 'undefined' && is_vp != 'cmpid_adwords') {
            if(!jQuery.browser.msie) {
                $("#bloc_erreur_vp").fadeOut(400);
            } else {
                document.getElementById('bloc_erreur_vp').style.display = 'none';
            }
            document.getElementById('bloc_erreur_vp').style.zIndex = '0';
        }
        else {
            if(!jQuery.browser.msie) {
                $("#bloc_erreur").stop(true, true).fadeOut(400);
            } else {
                document.getElementById('bloc_erreur').style.display = 'none';
            }
            document.getElementById('bloc_erreur').style.zIndex = '0';
        }
    }
}


function getXY(obj)
{
  var curleft = 0;
  var curtop = obj.offsetHeight + 5;
  var border;
  if (obj.offsetParent)
  {
    do
    {
      if (getStyle(obj, 'position') == 'relative')
      {
        if (border = _pub.getStyle(obj, 'border-top-width')) curtop += parseInt(border);
        if (border = _pub.getStyle(obj, 'border-left-width')) curleft += parseInt(border);
      }
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
    }
    while (obj = obj.offsetParent)
  }
  else if (obj.x)
  {
    curleft += obj.x;
    curtop += obj.y;
  }
  return {'x': curleft, 'y': curtop};
}
function getStyle(obj, styleProp)
{
  if (obj.currentStyle)
    return obj.currentStyle[styleProp];
  else if (window.getComputedStyle)
    return document.defaultView.getComputedStyle(obj,null).getPropertyValue(styleProp);
}
function fadePanier( id, nbelem ) {
    for( var i=1 ; i<=nbelem ; i++ ) {
        if( id != i && $("#texte_question_"+i).css('display') == 'block' ) $("#texte_question_"+i).slideUp(400);
    }
    if( $("#texte_question_"+id).css('display') != 'block' ) $("#texte_question_"+id).slideDown(400);
    else $("#texte_question_"+id).slideUp(400);
}
function fadeHelp( id, nbelem ) {
    for( var i=1 ; i<=nbelem ; i++ ) {
        if( id != i && $("#answer_"+i).css('display') == 'block' ) $("#answer_"+i).slideUp(400);
    }
    if( $("#answer_"+id).css('display') != 'block' ) $("#answer_"+id).slideDown(400);
    else $("#answer_"+id).slideUp(400);
}
function showBlocForm( id, type ) {
    clearTimeout(timer);
    if( $('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block' ) $('#bloc_erreur').css('display','none');
    if( id == 'is_not_client' ) {
        if(!jQuery.browser.msie) $("#is_client_form").fadeOut(600);
        else $("#is_client_form").css('display','none');
        $("#is_client").attr('checked', false);
        $("#is_client").prev().css('backgroundPosition','0 0');
        if($("#bloc_lost_password").css('display') == 'table-row' || $("#bloc_lost_password").css('display') == 'block') setTimeout("reloadBlocLogin()",1000);
    }else if( id == 'is_client' ) {
        if(!jQuery.browser.msie) $("#is_not_client_form").fadeOut(600);
        else $("#is_not_client_form").css('display','none');
        $("#is_not_client").attr('checked', false);
        $("#is_not_client").prev().css('backgroundPosition','0 0');
    }
    if( type == 'from_div' ) {
        $("#"+id).attr('checked', true);
        $("#"+id).prev().css('backgroundPosition','0 -24px');
    }
    if(!jQuery.browser.msie) {
        $("#"+id+"_form").fadeIn(600,function() {
        });
    }else {
        $("#"+id+'_form').css('display','block');
    }
}
function afficheLostPassword() {
    if( $('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block' ) $('#bloc_erreur').css('display','none');
        $("#bloc_form_inscription").fadeOut( 'slow' ,function() {
            $("#bloc_lost_password").fadeIn();
            $('#act').val('new mdp link');
            $('#mailLost').val($('#clientMailAuth').val());
        });
}
function reloadBlocLogin() {
    $("#bloc_lost_password").fadeOut('slow' ,function() {
        $("#bloc_form_inscription").fadeIn();
         $('#act').val($('#act_old').val());
    });
}
function afficheLostPasswordTop() {
    if( $('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block' ) $('#bloc_erreur').css('display','none');
    if(!jQuery.browser.msie) {
        $("#bloc_login_top").fadeOut(600);
        setTimeout("openInfobulle('bloc_lost_password_top')",600);
    }
    else {
        $("#bloc_login_top").css('display','none');
        openInfobulle('bloc_lost_password_top');
    }
}
function reloadBlocLoginTop() {
    if( $('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block' ) $('#bloc_erreur').css('display','none');
    if(!jQuery.browser.msie) {
        $("#bloc_lost_password_top").fadeOut(600);
        setTimeout("openInfobulle('bloc_login_top')",1000);
    }
    else {
        $("#bloc_lost_password_top").css('display','none');
        setTimeout("openInfobulle('bloc_login_top')",1000);
    }
}
function show_soldes() {
    if($("#wrapper_bandeau_soldes").queue("fx") == '') {
        $("#wrapper_bandeau_soldes").animate({
            height: "133px"
        }, 500);
    }
}
function hide_soldes() {
    $("#wrapper_bandeau_soldes").animate({
        height: "28px"
    }, 250);
}
var choix_relais_panner;
function display_choix_relais(action) {
    if (choix_relais_panner) choix_relais_panner = choix_relais_panner.destroy();
    if( action == 'show' ){
        $("#livraison_kiala").animate({
                top: "-=0"
        }, 500, function() {
            $("#choix_relais_kiala").slideDown("slow");
            $("#btn_choisir_livraison_relais").fadeOut();
            $("#btn_another_relay").hide();
            $("#btn_close_another_relay").show();
            choix_relais_panner = $('#records', this).get(0);
            choix_relais_panner = new Grabber(choix_relais_panner);
            $("#locateandselect #searchform_submit").click();
        });
    }else{
        $("#choix_relais_kiala").slideUp("slow");
        $("#btn_another_relay").show();
        $("#btn_close_another_relay").hide();
        $("#btn_choisir_livraison_relais").fadeIn();
        $("#livraison_kiala").animate({
                top: "+=0"
        }, 500, function() {

        });
    }
}
var choix_colissimo_panner;
function display_choix_colissimo(action) {
    if (choix_colissimo_panner) choix_colissimo_panner = choix_colissimo_panner.destroy();
    if( action == 'show' ){
        $("#autre_adresse_colissimo").slideDown(function () {
            choix_colissimo_panner = document.getElementById('colissimo_panner');
            choix_colissimo_panner = new Grabber(choix_colissimo_panner);
        });
        $("#show_close_another_colissimo").hide();
        $("#btn_choisir_livraison_colissimo").fadeOut();
        $("#btn_close_another_colissimo").show();
    }else{
        $("#autre_adresse_colissimo").slideUp();
        $("#show_close_another_colissimo").show();
        $("#btn_choisir_livraison_colissimo").fadeIn();
        $("#btn_close_another_colissimo").hide();
    }
}
function toggleFullScreen(){
    var video = document.getElementById("video_spa");
    if(video.requestFullScreen){ video.requestFullScreen();
    } else if(video.webkitRequestFullScreen){ video.webkitRequestFullScreen();
    }     else if(video.mozRequestFullScreen){ video.mozRequestFullScreen(); }
}
function close_popup( elem ) {
    var modbox = document.getElementById(elem);
    var shad = document.getElementById('shad');

    $("#shad").attr('onclick','');

    shad.style.display="none";
    modbox.style.display="none";
}
$(document).ready(function(){
    $(".bg_autres_rayons").each(function() {
        $(this).hover(function() {
            $(this).children("div").children(".wrapper_ul_autres_rayons").show();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").addClass("selected");
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").addClass("gras_rose");
        }    , function() {
            $(this).children("div").children(".wrapper_ul_autres_rayons").hide();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").removeClass('gras_rose noClass').addClass('');
        });
    })
});
$(document).ready(function(){
        $("#bg_liste_ariane").hover(function() {
            $(this).children(".wrapper_liste_ariane").show();
            $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").addClass("selected");
        }    , function() {
            $(this).children(".wrapper_liste_ariane").hide();
            $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
        });
});
function show_lightbox_inscrit(popup_confirm_inscription) {
    $('#shad_popup_confirm_inscription').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display','block');
        $('#'+popup_confirm_inscription).slideDown("slow");
        $(this).dequeue();
    });
}
function close_lightbox_inscrit(popup_confirm_inscription) {
    $('#'+popup_confirm_inscription).slideUp("slow").queue(function () {
        $('#shad_popup_confirm_inscription').fadeTo("slow", 0, function() {
            $(this).css('display','none');
        });
        $(this).dequeue();
    });
}
var old_onclick_shad = 'popup_confirm_reinitialisation_mdp';
function show_lightbox(container) {
    $('#shad').fadeTo('slow', 0.8).queue(function () {
        $(this).css('display', 'block');
        $(document.getElementById(container)).slideDown('slow');
        $(this).dequeue();
    });
    $('#shad').click(function () {
        close_lightbox(container);
    });
}
function close_lightbox(container) {
    container = document.getElementById(container);
    $(container).slideUp('slow').queue(function () {
        $('#shad').fadeTo('slow', 0, function () {
            $(container).hide();
        });
        $('#shad').hide();
        $(container).dequeue();
    });
    $('#shad').click(function () {
        close_lightbox(old_onclick_shad);
    });
}
function show_lightbox_3xcb() {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display','block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click',close_lightbox_3xcb);
        $(this).dequeue();
    });
}
function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    $(this).unbind('click',close_lightbox_3xcb);
}
function in_array(needle, haystack, strict) {
    var rKey = -1, strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
            rKey = key;
            break;
            }
        }
    }
    return rKey;
}
function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}
var elmt;
var loader;
var prev_display;
function waitingProcess(src,target_id,type,id_form,tab_erreur){
    if(src){
        elmt = $("#"+$(src).attr('id'));
    }
    if(target_id){
        loader = $("#"+target_id);
    }
    if( elmt.css('display')!='none'){
        prev_display = elmt.css('display');
        elmt.css('display', 'none');
        loader.css('display', 'block');
        switch(type) {
            case 0:window.setTimeout('ajax_checkAllForm(tabAuthTop, 1, path_relative_root)', 1000);break;
            case 1:window.setTimeout('ajax_checkAllForm(tabLostPwdTop, 1, path_relative_root)', 1000);break;
            case 2:window.setTimeout('ajaxDeconnect(path_relative_root);', 1000);break;
            case 3:window.setTimeout('checkAllForm(tabError,0,path_relative_root,\''+id_form+'\');', 1000);break;
            case 4:window.setTimeout('ajax_checkAllForm(tabAuth, 1, path_relative_root)', 1000);break;
            case 5:window.setTimeout('ajax_checkAllForm(tabLostPwd, 1, path_relative_root)', 1000);break;
            case 6:window.setTimeout('checkFormParrainage()', 1000);break;
            case 7:window.setTimeout('if(checkAllForm(tabErrorGrattez,0,path_relative_root,\''+id_form+'\')) processGrattezGagnez();', 1000);break;
        }
    }
}
function hideLoader()
{
    if (loader)    { loader.css('display', 'none'); }
    if (elmt)    { elmt.css('display', prev_display); }
}
$.fn.recalImg = function (img){
    var obj = $(this);
    var img;
    if(img == "" || img == null){
        img = $("img", obj);
    }else{
        img = $(img, obj);
    }
    img.css({position:"",height:"", width:"", left:"", top:"",marginTop:"",marginLeft:""});
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);

    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({position:"relative"});
    img.css({position:"absolute"});
    if( ( heightImg > heightObj ) || ( widthImg > widthObj ) ){
        if(widthImg > heightImg){
            img.css({height:heightObj});
        }else if(widthImg <= heightImg){
            img.css({width:widthObj});
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow)/2;
        var difWidth = (widthObj - widthImgNow)/2;
        if(widthImg > heightImg){
            img.css({left:0, top : 0});
        }else if(widthImg <= heightImg){
            img.css({top:0, left : 0});
        }
    }else{
        img.css({top:"0", left:"0"});
    }
    img.show();
};
function activeChange(p,c,s){
    if($(p).attr("value") == ""){
        $(c).css({color:"#aaa",fontStyle:"italic"});
    }else{
        $(c).css({color:"#000",fontStyle:"normal"});
    }
    c.text($('option:selected',s).text());
    if($(s).attr("value") == ""){
        $(c).css({color:"#aaa",fontStyle:"italic"});
    }
}
$.fn.selectStyled = function(){
    var p = $(this);
    if (!$(".clone", p).length){

        p.prepend('<div class="clone"></div>');
    }
        var c = $('.clone', p);
        var s = $('select', p);
        var ws = $(s).outerWidth();
        var hs = $(s).outerHeight();
        var ts = 2;
        var ls = 5;
        activeChange(p,c,s);
        s.css({"display":"block","opacity":"0","position":"absolute","top":"0","left":"0"});
        c.text($('option:selected',s).text());
    $(s).keypress(function(){
        activeChange(p,c,s);
    });
    s.change(function(){
        activeChange(p,c,s);
    });
}
$(function(){
    $('.selectStyled').each(function(){
        $(this).selectStyled();
    });
});
$.fn.cbForm = function(){
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({opacity:"1"});
    fs.hover(function(){
        if(!$(this).hasClass("activ") && t.hasClass('active_bloc')){
            $(this).css({opacity:"0.5"});
        }
    },function(){
        if(!$(this).hasClass("activ") && t.hasClass('active_bloc')){
            $(this).css({opacity:"1"});
        }
    });
        fs.click(function(){
            if(t.hasClass('active_bloc')){
                fs.removeClass("activ");
                fs.css({opacity:"1"});
                $(this).addClass("activ");
                $(this).css({opacity:"0.5"});
                $(".type_carte option",t).removeAttr("selected");
                var typeCarte = $(this).attr("id");
                $("#type_carte_"+typeCarte ,t).attr('selected', 'selected');
                p = $('.type_carte option:selected',t);
                c = $('.clone',$('.type_carte',t).parent('.selectStyled'));
                c.text(p.text());
                if($(p).attr("value") == ""){
                    $(c).css({color:"#aaa",fontStyle:"italic"});
                }else{
                    $(c).css({color:"#000",fontStyle:"normal"});
                }
            }
        });
        $('.type_carte',t).change(function(){
            tcv = $('option:selected', this);
            var ctcv = tcv.val();
            fs.removeClass("activ");
            fs.css({opacity:"1"});
            if( ctcv != ""){
                $('.form_select#'+ctcv).addClass("activ");
                $('.form_select#'+ctcv).css({opacity:"1"});
            }
        });
}
$.fn.submitLastForm = function (){
    $(this).parents("form").submit();
}
function showerrormessage(){
    var elname = 'modboxpromo';
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $('#'+elname).slideDown("slow");
        $(this).dequeue();
    });
    addEventPromo('click',closeerrormessage);
    if (typeof(actualposition) == 'undefined')
    {
        actualposition = document.documentElement.scrollTop;
    }
    window.scrollTo(0, actualposition);
}
function closeerrormessage() {
    $('#modboxpromo').slideUp("slow").queue(function () {
        $('#shad').fadeTo("slow", 0);
        $(this).dequeue();
        $('#shad').hide();
        $('#modboxpromo').hide();
    });
    delEventPromo('click',closeerrormessage);
}
function showrecuppanierlightbox(){
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop ;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display="block";
    modbox.style.display="block";
    if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1 ) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad,'click',closerecuppanier);
    window.scrollTo(0,actualposition);
}
function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft='';
    shad.style.display="none";
    modbox.style.display="none";
    document.body.style.overflow='';
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad,'click',closerecuppanier);
}
function addEventPromo(event,name_function) {
    if(document.all) document.getElementById('shad').attachEvent("on"+event,name_function);
    else document.getElementById('shad').addEventListener(event,name_function,false);
}
function delEventPromo(event,name_function) {
    if(document.all) document.getElementById('shad').detachEvent("on"+event,name_function);
    else document.getElementById('shad').removeEventListener(event,name_function,false);
}
function addFileInput(id_input){
    id = parseInt(id_input);
    var next_file = id + 1;
    var file = $('#contactProduitPhoto'+id_input)[0].files[0];
    var error = false;
    var nb_input = $('#photoProduit_display').val();
    nb_input = parseInt(nb_input);
    size = file.size;
    type = file.type;
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf');
    if (size>4000000){
        alert('Poids Max : 4Mo');
        error =  true;
    }else if (jQuery.inArray(type, allowedType) == -1){
        alert('Format autoris� : jpg, png, pdf');
        error =  true;
    }
    if (error) {
        if( id_input == 1 ){
            $('#field_send').addClass('cache');
            $('#contactProduitPhoto'+next_file).remove();
        }
        $('#contactProduitPhoto'+id_input).val('');
    } else {
        if( id_input == 1 ){
            $('#field_send').removeClass('cache');
        }
        if(next_file < 4 && $('#contactProduitPhoto'+id_input).val != '' && nb_input < 3){
            $('#contactProduitPhoto'+id_input).after("<input class='product_file' id='contactProduitPhoto" + next_file + "' value='' type='file' name='contactProduitPhoto" + next_file + "' onchange='addFileInput(" + next_file + ")' /> ");
            $('#photoProduit_display').attr('value', next_file);
        }
    }
}
function checkDateContact(){
    var jour = $('#contactDateCommandeJ').val();
    var mois = $('#contactDateCommandeM').val();
    var annee = $('#contactDateCommandeA').val();
    var field_error = $('#field_error_date_garantie td');
    field_error.empty();
    if(jour != "" && mois != "" && annee !=""){
        var date_selec = new Date(annee + '/' + mois + '/' + jour);
        date_selec = Math.round(date_selec.getTime() / 1000);
        var date = new Date();
        date = Math.round(date.getTime() / 1000);
        var deux_ans = 63072000;
        var diff_deuxans = date - date_selec;
        if(diff_deuxans > deux_ans){
                field_error.append(ajax_file( 'ajax_sprintf.php?arg1=contact_field_error_garantie' ));
                $('input[name="contactGarantieProduit"]').removeAttr('checked')
                $('#field_error_garantieProduit td').empty();
                $('#field_garantieproduit').addClass('cache');
                $('#field_error_garantieProduit').addClass('cache');
                $('#field_garantieNomProduit').addClass('cache');
                $('#field_message').addClass('cache');
                $('#field_photo').addClass('cache');
                $('#field_send').addClass('cache');
                $('#field_error_date_garantie').removeClass('cache');
        }else{
            $('#field_garantieproduit').removeClass('cache');
            $('#field_error_garantieProduit').removeClass('cache');
            $('#field_error_date_garantie').addClass('cache');
        }
    }else{
        $('#field_garantieproduit').addClass('cache');
        $('#field_error_garantieProduit').addClass('cache');
        $('#field_preuveAchat').addClass('cache');
        $('#field_garantieNomProduit').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
        $('#field_error_garantieProduit td').empty();
    }
}
function checkProduitContact(idProduit){
    $('#contactMessage').empty();
    $('#contactProduitPhoto1').val("");
    $('#contactProduitPhoto2').remove();
    $('#contactProduitPhoto3').remove();
    $('#field_send').addClass('cache');
    if( idProduit != ""){
        tab = new Array();
        tab[0] = new Array('alpha','contactNom','contact_field_nom');
        tab[1] = new Array('alpha','contactPrenom','contact_field_prenom');
        tab[2] = new Array('mail','contactMail','contact_field_mail');
        tab[3] = new Array('no_empty','contactMessage','contact_field_message');
        $('#field_message').removeClass('cache');
        $('#field_photo').removeClass('cache');
    }else{
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
    }
}
function checkField( id, type, value ) {
    var current_val = $("#"+id).val();
    if( type == 'focus' ) {
        if( current_val == value )
            $("#"+id).val('');
    }
    else {
        if( current_val == '' )
            $("#"+id).val(value);
    }
}
function autocomplete_nomgarantie(){
    var value = $('#garantieNomProduit').val();
    var values = { search : value };
    if( value.length > 2 ) {
        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_produit.php',
            'async': false,
            'success': function(response) {
                autoCompleteAll(response, 'produit', value);
            }
        });
    }
}
function openPopup( popup_id, shad_id, opacity ){
    if( shad_id == undefined ){
        shad_id = 'shad';
    }
    if(opacity == undefined){
        opacity = 0.8;
    }
    var $popup = $( '#' + popup_id );
    var $shad = $( '#' + shad_id );
    if($shad.length == 0){
        $popup.before('<div id="'+shad_id+'"></div>');
        $shad = $( '#' + shad_id );
        $shad.hide();
    }
    $shad.bind( 'click.popup', function(){
        closePopup( popup_id, shad_id );
    });
    $shad.add( $popup ).appendTo( 'body' );
    $popup.css({opacity : 0, display : 'block'});
    $shad.fadeTo( 'slow', opacity, function(){
        $popup.animate({opacity : 1}, function(){
            $popup.css({opacity : '', display : 'block'});
        });
    });
    $shad.css({display : 'block', opacity : 0}).animate({opacity : 0.8},function() {
        $('#wrapper_sendwishlist').css({opacity : ''});
        $popup.slideDown( 'slow' );
    });
}
function closePopup( popup_id, shad_id ){
    if( shad_id == undefined ){
        shad_id = 'shad';
    }
    var $popup = $( '#' + popup_id );
    var $shad = $( '#' + shad_id );
    $shad.unbind( 'click.popup' );
    $popup.fadeOut( function(){
        $shad.fadeOut( 'slow' );
    });
}
function ToggleCollapsible() {
    var element = $(this);
    var content = element.siblings();
    if (element.hasClass("collapsible-closed")) {
        content.slideDown();
        element.removeClass("collapsible-closed").addClass("select_btn");
    } else {
        content.slideUp();
        element.addClass("collapsible-closed").removeClass("select_btn");
    }
}
function openMultiShad(id, fixed) {
    var $lbId = $('#'+id);
    $("#shad").fadeIn();
    if($lbId.length){
        $lbId.css({opacity :0, display : 'block'})
            .animate({opacity : 1},350,function(){
                $lbId.css({opacity : ''});
            });
        $('#shad').fadeIn().bind('click', closeMultiShad);
        if ( navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1 ) {
            $('#topMenuConnexionButtonInside').css({zIndex : 1});
        }
    }
}
function closeMultiShad() {
    var elements = [
        '#lightbox_achat_express',
        '#modbox',
        '#modbox_parrainage',
        '#modbox_error',
        '#popup_savoir_plus',
        '#sizeguidebox',
        '#sizeguideboxbague',
        '#alertbox',
        '#alertbox1',
        '#alertboxlogin',
        '#sendfriendbox',
        '#popup_inscription_home',
        '#errbox',
        '#popup_prix_reference',
        '#modboxpromo',
        '#gravurePopup',
        '#gravurePopupAssoc',
        '#selectionOffertePopup',
        '#sendwishlistbox'
    ].join(', ');
    $(elements).fadeOut();
    $('#shad').fadeOut();
}
function deplieMsg( id ) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_'+id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}
$(document).ready(function(){
    $("#connexion_top").click(function(e){
        if($(e.target).attr('id') == 'connexion_top')
            window.location = window.ROOT_PATH + create_link('client_index');
    });
});

var cgv, cgv_panner;
function popCGV() {
	$.ajax({
		url : path_relative_root + create_link('order_cgvpopup'),
		type : 'get',
		success : function (res) {
			cgv = $('#popup_cgv');
            res = $('#wrapper_contact', res);
            res.find('a').each(function () {
                $(this).replaceWith($(this).text());
            });
			$('.cgv_container').html(res);
			cgv.fadeIn(160, function () {
				$('.popup_cgv').slideDown(480, function () {
					cgv_panner = new Grabber(res.get(0));
				});
			});
		}
	});
}

function popCGVClose() {
    cgv = $('#popup_cgv');
    $('.popup_cgv').slideUp(480, function () {
            cgv_panner = cgv_panner.destroy();
            cgv.fadeOut(160);
    });
}

var charte, charte_panner;
function popCharte() {
	$.ajax({
		url : path_relative_root + create_link('satellite_protectiondonnees'),
		type : 'get',
		success : function (res) {
			charte = $('#popup_charte');
            res = $('#wrapper_content_sat', res);
			$('.charte_container').html(res);
			charte.fadeIn(160, function () {
				$('.popup_charte').slideDown(480, function () {
					charte_panner = new Grabber(res.get(0));
				});
			});
		}
	});
}

function popCharteClose() {
    charte = $('#popup_charte');
    $('.popup_charte').slideUp(480, function () {
        charte_panner = charte_panner.destroy();
            charte.fadeOut(160);
    });
}

(function () {
    'use strict';
    var bubble, toggle;
    function toggleBubble() {
        var param = toggle ? ['fadeOut', 640, 60000] : ['fadeIn', 900, 15000];
        toggle = !toggle;
        this.jQuery(bubble)[param[0]](param[1]);
        return this.setTimeout(toggleBubble, param[2]);
    }
    function initBubble() {
        var res = false;
        this.removeEventListener('load', initBubble);
        bubble = this.document.getElementById('appel_vendeur');
        toggle = false;
        if (bubble) {
            res = this.setTimeout(toggleBubble, 60000);
        }
        return res;
    }
    return this.addEventListener('load', initBubble);
}).call(window);
$(function () {
    $('body').on('click', '.submit_wrapper .submit', function () {
        var active_elm = $(this).closest(':focus');
        if (active_elm.length > 0) {
            active_elm.blur();
        }
        $(this).next('.loader').show();
        $(this).fadeOut(600);
    });
});
(function () {
    'use strict';
    function parseArgs(args) {
        var obj = {},
            inc = 0,
            len = args.length;
        for (inc; inc < len; inc += 1) {
            obj['arg' + inc] = args[inc];
        }
        return obj;
    }
    function translate() {
        var o_data = parseArgs(arguments),
            s_resp = arguments[0];
        $.ajax({
            url : path_relative_root + 'ajax_sprintf.php',
            type : 'get',
            data : o_data,
            async : false,
            success : function (res) { s_resp = res }
        });
        return s_resp;
    }
    window.translate = translate;
}());

function changeLocale( locale, uri, site ){

    $.ajax({
        url: path_relative_root+'ajax_get_uri_by_lang.php',
        type: 'post',
        data: { lang: locale, url:uri, site : site },
        success: function (data) {
            if (data != '') {
                                location.reload();
            }
        }
    });

}

/***MODAL***/
/**
 * Gestion des modal (modbox)
 */

function showBoxModal(elementID) {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $('#'+elementID).slideDown("slow");
        $(this).dequeue();
    }).click(function(){
        close_lightbox(elementID);
    });
}

function closeBoxModal(elementID) {
    $('#'+elementID).slideUp("slow").queue(function () {
        $('#shad').fadeTo("slow", 0, function() {
            $(this).css('display','none');
        });
        $(this).dequeue();
    });
}
