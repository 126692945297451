function getXMLHttpRequest() {
	var xhr = null;
	if (window.XMLHttpRequest || window.ActiveXObject) {
		if (window.ActiveXObject) {
			var tableau_xhr_activeX = [
				'Msxml2.XMLHTTP.6.0',
				'Msxml2.XMLHTTP.3.0',
				'Msxml2.XMLHTTP',
				'Microsoft.XMLHTTP'
			];
			for (var value in tableau_xhr_activeX) {
				try {
					xhr = new ActiveXObject(tableau_xhr_activeX[value]);
					break;
				} catch(error) {};
			};
		} else {
			xhr = new XMLHttpRequest();
		};
	} else {
		return null;
	};
	return xhr;
};
function ajax_file(fichier) {
	 if(window.XMLHttpRequest) // FIREFOX-
		  xhr_object = new XMLHttpRequest();
	 else if(window.ActiveXObject) // IE
		  xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
	 else
		  return(false);
	 xhr_object.open("GET", fichier, false);
	 xhr_object.send(null);
	 if(xhr_object.readyState == 4) return(xhr_object.responseText);
	 else return(false);
}
function ajax_file_XML(fichier) {
	 if(window.XMLHttpRequest) // FIREFOX-
		  xhr_object = new XMLHttpRequest();
	 else if(window.ActiveXObject) // IE
		  xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
	 else
		  return(false);
	 xhr_object.open("GET", fichier, false);
	 xhr_object.send(null);
	 if(xhr_object.readyState == 4) return(xhr_object.responseXML);
	 else return(false);
}
function showNumRetourEtiquette(idOrder, multiTransp, parentPath, idOrderTransp, idTransporteur) {
	var numDIVObjet = document.getElementById('popup_numero_retour');
	if(multiTransp == 1){
		var numObjet = document.getElementById('num_retourDIV_' + idTransporteur);
		var divPrintPdfObjet = document.getElementById('printPdf_' + idTransporteur);
	}
	else{
		var divPrintPdfObjet = document.getElementById('printPdf');
		var numObjet = document.getElementById('num_retourDIV');
	}
	var numDiv_coli = document.getElementById('colissimo');
	var numDiv_mory = document.getElementById('mory');
	if(multiTransp == 1){
		var divCreateNumRetourObjet = document.getElementById('createNumRetour_' + idTransporteur);
		var numRetourObjet			= document.getElementById('num_retour_' + idTransporteur);
	}else{
		var divCreateNumRetourObjet = document.getElementById('createNumRetour');
		var numRetourObjet			= document.getElementById('num_retour');
	}
	var numRetour = '';
	switch (idTransporteur){
		case 1:
			if( numDiv_coli ) numDiv_coli.style.display = 'block';
			if( numDiv_mory ) numDiv_mory.style.display = 'none';
			break;
		case 2:
			if( numDiv_coli ) numDiv_coli.style.display = 'none';
			if( numDiv_mory ) numDiv_mory.style.display = 'block';
			break;
		case 4:
			if( numDiv_coli ) numDiv_coli.style.display = 'none';
			if( numDiv_mory ) numDiv_mory.style.display = 'block';
			break;
	}
	if( typeof(idOrderTransp) != 'undefined' )
		var numRetour = ajax_file( parentPath + 'ajax_num_etiquette.php?idOrder=' + idOrder + '&multiTransp=' + multiTransp + '&idOrderTransp=' + idOrderTransp);
	else
		var numRetour = ajax_file( parentPath + 'ajax_num_etiquette.php?idOrder=' + idOrder + '&multiTransp=' + multiTransp);
	if (numRetour!='false'){
		numDIVObjet.style.display = 'block';
		numObjet.innerHTML = numRetour;
		numRetourObjet.innerHTML = numRetour;
		divCreateNumRetourObjet.style.display = 'none';
		divPrintPdfObjet.style.display = 'block';
		document.getElementById('shad').style.display = 'block';
	}
}
function createEtiquetteRetour(idOrder, parentPath, multiTransp){
	if(multiTransp == 1){
		var choixRetourDivObjet = document.getElementById('choixRetourDiv_');
		var choixRetourObjet = document.getElementById('choixRetour_');
	}else{
		var choixRetourDivObjet = document.getElementById('choixRetourDiv');
		var choixRetourObjet = document.getElementById('choixRetour');
	}
	var choix = 0;
	if (choixRetourObjet.value != '0')
		choix = choixRetourObjet.value;
	else
		choix = choixRetourDivObjet.value;
	switch (choix){
		case '1':
			var txtRaison = 'Droits de r�tractation';
			break;
		case '2':
			var txtRaison = 'Article non conforme � la commande';
			break;
		case '3':
			var txtRaison = 'Article Cass� / D�fectueux';
			break;
		case '4':
			var txtRaison = 'Probl�me de Taille / Qualit�';
			break;
		case '5':
			var txtRaison = 'Autres';
			break;
	}
	var response = ajax_file( parentPath + 'ajax_create_etiquette.php?idOrder=' + idOrder + '&choix=' + txtRaison + '&numChoix=' + choix +'&multiTransp=' + multiTransp);
	if( response.substr(0,5) == 'false' ){
		alert( ajax_file( parentPath + 'ajax_sprintf.php?arg1=js_error_return_label ' ) );
	}else{
		var listeRaisonBlocObjet = document.getElementById('liste_motif_retour');
		var btnPrintPdfBlocObjet = document.getElementById('btnPrintPdf');
		if(txtRaison == "Autres"){
			listeRaisonBlocObjet.innerHTML = '<u>Raison du retour</u> : ' + txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
		}
		else
		{
			listeRaisonBlocObjet.innerHTML = txtRaison + '<input type="hidden" id="choixRetour" value="'+choix+'">';
		}
		btnPrintPdfBlocObjet.style.display = 'block';
		document.getElementById('shad').style.display = 'none';
		document.getElementById('popup_numero_retour').style.display = 'none';
		window.open(response);
	}
}
function showSousCategOptionListe(objCateg, objSousCategId, objetId, idSousCateg ) {
	var optionSousCateg = document.getElementById(objSousCategId);
	var optionObjet = document.getElementById(objetId);
	if ( objCateg.value != 'non_attribue' ){
		var response = ajax_file('../ajax/ajax_sousCateg_optionliste.php?idCateg=' + objCateg.value
								 + '&index=' + objSousCategId.split('_')[1] + '&idSousCateg=' + idSousCateg);
		optionSousCateg.style.display = 'block';
		optionObjet.style.display = 'none';
		if (response == '') { optionSousCateg.innerHTML = ''; optionObjet.innerHTML = ''; }
		else { optionSousCateg.innerHTML = response; optionObjet.innerHTML = ''; }
	}else{
		optionSousCateg.innerHTML = '';
		optionObjet.innerHTML = '';
	}
}
function showObjetOptionListe(objSousCateg,objetId, idObjet) {
	var optionObjet = document.getElementById(objetId);
	var response = ajax_file('../ajax/ajax_objet_optionliste.php?idSousCateg=' + objSousCateg.value
							 + '&index=' + objetId.split('_')[1] + '&idObjet=' + idObjet);
	optionObjet.style.display = 'block';
	if (response)
		optionObjet.innerHTML = response;
	else
		optionObjet.innerHTML = '';
}
function traiteXmlQte(xmlDoc) {
  var options = xmlDoc.getElementsByTagName('option');
  var optionsListe = new Array();
  for (var i=0; i < options.length; ++i) {
  	var optionsListe2 = new Array();
	var id 		= options.item(i).getElementsByTagName('id');
	var label 	= options.item(i).getElementsByTagName('label');
	var color 	= options.item(i).getElementsByTagName('color');
	optionsListe2.push(id.item(0).firstChild.data,label.item(0).firstChild.data,color.item(0).firstChild.data);
	optionsListe.push(optionsListe2);
  }
  return optionsListe;
}
function showQteListe(elementID, idProduit, parentPath)
{
	var couleurs_id = 'couleurProd';
	var tailles_id  = 'tailleProd';
	if (elementID != 'liste_qte')
	{
		couleurs_id += '_'+idProduit;
		tailles_id  += '_'+idProduit;
	}
	var listQte = $('#'+elementID);
	var alternative_listQte = $('#liste_qte_spe');
	if (listQte.length || alternative_listQte.length)
	{
		if(listQte.length)
		{
			listQte.html('<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' + parentPath + 'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>');
			var couleurs = ( ($('#'+couleurs_id).length) ? $('#'+couleurs_id).val() : 'none' );
			var tailles = ( ($('#'+tailles_id).length) ? $('#'+tailles_id).val() : 'none' );
		}
		else
		{
			alternative_listQte.html(listQte.html());
			var couleurs = 'none';
			var tailles = 'none';
		}
		$('.restock_date').remove();
		$('.info_magasin').hide();

		var response = ajax_file(parentPath + 'ajax_liste_qte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + '&produit_lot_nb='+$("#produit_lot_nb").val());
		if (response)
		{
			if (listQte.length)
			{
				listQte.html(response);
			}
			else
			{
				alternative_listQte.html(response);
			}

			$('.selectStyled').each(function(){
				$(this).selectStyled();
			});
			var select_stock = $('#qteProd');
			var regexp_epuise = /Stock /;
			var txt_stock = $('#qteProd>option:selected').text();

			$(".eclat_nouveaute").hide();

			if($('.restock_date').length>0) {
                $('.info_magasin').append($('.restock_date'));
                $('.info_magasin').show();
            }

            if ($("#date_du_ajax").val() == 0 && $("#date_au_ajax").val()) {
                $("#rassurance_lad").hide();
            } else {
                $("#rassurance_lad").show();
                $("#date_du").html($("#date_du_ajax").val());
                $("#date_au").html($("#date_au_ajax").val());
            }

			if ($('.show_picto_arrivage').length && $('.show_picto_arrivage').val() == '1') {
				$(".eclat_nouveaute").show();
			}

			if ($('.stock_tampon').length && $('.stock_tampon').val() <= 0) {
				$("#rassurance_lad").hide();
			}
			else {
				$("#rassurance_lad").show();
			}

			// Modification de la date d'arrivage à la volée en js car modifié après avoir généré la clé de traduction
			if ($('.date_ajax').length) {
				$('.date_ajax').html($('#date_au_ajax').val());
			}
		}
	}
}
function showQteListeGamme(elementID, idProduit,parentPath){
	var listQte = document.getElementById(elementID);
	var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);
	if (listQte || alternative_listQte)
	{
		if (listQte)
		{
			if(document.getElementById('couleurProd_'+idProduit)) var couleurs = document.getElementById('couleurProd_'+idProduit).value;
			if(document.getElementById('tailleProd_'+idProduit)) var tailles = document.getElementById('tailleProd_'+idProduit).value;
			listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
		}
		else
		{
			var couleurs = 'none';
			var tailles = 'none';
			alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
		}

		var string_params = '';
		if( $("#produit_lot_nb_"+idProduit).val() != undefined )
			string_params += '&produit_lot_nb=' + $("#produit_lot_nb_"+idProduit).val();

		var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs + '&idtaille=' + tailles + string_params);
		if (response)
		{
			if(listQte) listQte.innerHTML = response;
			else alternative_listQte.innerHTML = response;
		}
	}
}
function showQteListeAssoc(elementID, idProduit, parentPath) {
	var listQte = document.getElementById(elementID);
	var alternative_listQte = document.getElementById('liste_qte_spe_'+idProduit);
	listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
	var couleurs = document.getElementById('couleurProdAssoc_'+idProduit);
	var tailles = document.getElementById('tailleProdAssoc_'+idProduit);
	if (listQte || alternative_listQte) {
		if(listQte){
			var couleurs = document.getElementById('couleurProd_'+idProduit).value;
			var tailles = document.getElementById('tailleProd_'+idProduit).value;
			listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
		}
		else{
			var couleurs = 'none';
			var tailles = 'none';
			alternative_listQte.innerHTML = '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' + parentPath + 'img/sablier.gif" />Chargement...</td></tr></table>';
		}
			var response = ajax_file(parentPath + 'ajax_liste_qte_alerte.php?idprod=' + idProduit + '&idcouleur=' + couleurs.value + '&idtaille=' + tailles.value);
			if (response) {
				response = response.replace( 'qteProd_', 'qteProdAssoc_' );
				response = response.replace( 'qteProd_', 'qteProdAssoc_' );
				if(listQte) listQte.innerHTML = response;
				else alternative_listQte.innerHTML = response;
			}
	}
}
function verif_base(elementID, val, parentPath) {
	var mess = document.getElementById(elementID);
	if (elementID=='mess_num_commande') var variable = 'numCommande';
	else var variable = 'refProduit';
	if (mess) {
		var response = ajax_file(parentPath + 'ajax_verif_contact.php?'+variable+'='+ val );
		if (response) {
			mess.innerHTML = response;
			var nom_label = document.getElementById('contactNomLabel');
			var td_img = document.getElementById('preview_tof');
			if(variable=='refProduit') {
				if(!document.getElementById('erreur')) {
					var img_name = document.getElementById("img_name").value;
					var img_rep = document.getElementById("dir").value;
					var img_type = document.getElementById("image_type").value;
					var td = document.createElement('td');
					td.setAttribute('id','preview_tof');
					if (document.all){
						td.style.setAttribute('cssText','padding-left:45px;');
						if(img_type=='vert') td.style.setAttribute('cssText','padding-left:45px;vertical-align:top;padding-top:7px;');
					}
					else{
						td.setAttribute('style','padding-left:45px;');
						if(img_type=='vert') td.setAttribute('style','padding-left:45px;vertical-align:top;padding-top:7px;');
					}
					td.rowSpan=5;
					var img = document.createElement('img');
					var dest_img = img_rep+img_name+'.jpg';
					img.setAttribute('src', dest_img);
					img.setAttribute('id',img_name);
					if(img_type=='hor') img.setAttribute('class','cadre_photo_hor');
					else img.setAttribute('class','cadre_photo_vert');
					td.appendChild(img);
					if(!td_img) nom_label.parentNode.parentNode.appendChild(td);
				}
				else
				{
					var nom_label = document.getElementById('contactNomLabel');
					if(td_img) nom_label.parentNode.parentNode.removeChild(td_img);
				}
			}
		}
		else mess.innerHTML = "";
	}
}
function showTailleListeGamme(elementID, idProduit,parentPath, tailleProd){
	var eltaille = document.getElementById( elementID );
	if (eltaille)
	{
		var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);
		if (response)
		{
			eltaille.innerHTML = response;
		}
	}
}
function showTailleListeAssoc(elementID, idProduit, parentPath, tailleProd) {
	var eltaille = document.getElementById( elementID );
	if (eltaille) {
		var response = ajax_file(parentPath + 'ajax_liste_taille.php?idprod=' + idProduit + '&tailleprod = ' + tailleProd);
		if ( response ) {
			response = response.replace('tailleProd_', 'tailleProdAssoc_');
			response = response.replace('tailleProd_', 'tailleProdAssoc_');
			response = response.replace('liste_qte_', 'liste_qte_assoc_');
			response = response.replace('showQteListeGamme', 'showQteListeAssoc');
			eltaille.innerHTML = response;
		}
	}
}
function checkStocksProduct() {
	var id_product_element = document.getElementById('produit_id');
	if (id_product_element) {
		var id_product = id_product_element.value;
		var response = ajax_file(parentPath + 'ajax/ajax_check_stock.php?id_product='+id_product);
		if (response && response < 1) {
			alert(ajax_file(parentPath+'ajax_sprintf.php?arg1=js_error_no_stock'));
			return false;
		}
		return true;
	}
}
function ajax_classer(){
	var sortable1 = Sortable.serialize('list1');
	var sortable2 = Sortable.serialize('list2');
	var response = ajax_file('ajax/ajax_classer_categ.php?' + sortable1 + '&' + sortable2);
	if (response){
		var message = document.getElementById('messFinish');
		message.style.display = "block";
		Effect.Pulsate(message);
	}
}
function	showQteListePanier(elementID, idProduit, i, paniervirtuelId) {
	var listQtePanier = document.getElementById(elementID);
	var couleurs = document.getElementById('couleurProd'+i);
	var tailles = document.getElementById('tailleProd'+i);
	var idCouleur = 0;
	var idTaille = 0;
	if (couleurs) idCouleur = couleurs.value;
	if (tailles) idTaille = tailles.value;
	if (listQtePanier) {
			var response = ajax_file('ajax/ajax_liste_qte_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&i=' + i + '&panierId=' + paniervirtuelId );
			if (response)
				listQtePanier.innerHTML = response;
	}
}
function updatePanier(idProduit, i, paniervirtuelId){
	var couleurs = document.getElementById('couleurProd'+i);
	var tailles = document.getElementById('tailleProd'+i);
	var qte = document.getElementById('ProdQte'+i);
	var idCouleur = 0;
	var idTaille = 0;
	if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
	if (tailles && tailles.value != 'none') idTaille = tailles.value;
	ajax_file('ajax/ajax_upd_panier.php?idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte.value + '&panierId=' + paniervirtuelId);
}
function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur){
	if (i != ''){
		i = '_'+i;
		prixU = $('#prixU'+i).val();
	}
	var couleurs = document.getElementById('couleurProd'+i);
	var tailles = document.getElementById('tailleProd'+i);
	var $alertbox = $( '#alertbox1' );
	var res = '';
	var html_err = '';
	var flagError = false;
	if($('#lightbox_achat_express').length>0){
		from_range=true;
	}else{
		from_range=false;
	}
	html_err = '<div class="close_pop_ajout_panier" onclick="closeMultiShad();"></div><div class="picto"></div><div class="txt_alert"></div>';
	html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
	if ($alertbox.length == 0) {
		$alertbox = $('<div id="alertbox1">'+ html_err +'</div>');
		$('body').append($alertbox.hide());
	}
	if(tailles && tailles.value == '') {
		res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_size');
		flagError = true;
	} else if(couleurs && couleurs.value == '') {
		res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_couleur');
		flagError = true;
	} else if (document.getElementById('qteProd'+i) && document.getElementById('qteProd'+i).value == '') {
		res = ajax_file(path_web+'ajax_sprintf.php?arg1=js_error_quantity');
		flagError = true;
	}
    if (flagError)
    {
        $alertbox.find('.txt_alert').html(res);
        openMultiShad('alertbox1', 'fixed');
        return false;
    }
    else {
		var qte = 0;
		if ($('#qteProd'+i)){
			qte = $('#qteProd'+i).val();
		}
		var idCouleur = 0;
		var idTaille = 0;
		if (couleurs && couleurs.value != 'none') idCouleur = couleurs.value;
		if (tailles && tailles.value != 'none') idTaille = tailles.value;
		var array_response = new Array();
		var response = ajax_file(path_web+'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);
		if (response) {
			array_response = response.split("//");
			var designation = array_response[0];
			if ( titreObjet.match('^[0-9]') ) {
				if ( designation == 1 ) {
					designation += ' lot de';
				} else {
					designation += ' lots de';
				}
			}
			designation += ' ' + titreObjet;
			if ( document.getElementById(idElTaille) ) {
				if ( document.getElementById(idElTaille).selectedIndex ) {
					designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;
				} else if ( lastTaille != '' ) {
					designation += ' - Taille ' + lastTaille;
				}
			}
			if ( document.getElementById(idElCouleur) ) {
				if ( document.getElementById(idElCouleur).selectedIndex ) {
					designation += ' - ' + document.getElementById(idElCouleur).options[document.getElementById(idElCouleur).selectedIndex].text;
				} else if ( lastCouleur != '' ) {
					designation += ' - ' + lastCouleur;
				}
			}
			array_response[0] = designation;
		}
		return array_response;
	}
}
function showMontanTotalProduit(elementID, i){
	var montantProduit = document.getElementById(elementID);
	qte = document.getElementById("ProdQte"+i);
	prix = document.getElementById("prixProd"+i);
	var response = ajax_file('ajax/ajax_montant_total_produit.php?qte=' + qte.value + '&prix=' + prix.value );
	if (response)
				montantProduit.innerHTML = response;
}
function showMontanTotalPanier(elementID){
		var montantTotalPanier = document.getElementById(elementID);
		var response = ajax_file('ajax/ajax_montant_total_panier.php');
		if (response)
			montantTotalPanier.innerHTML = response;
}
function show_question(elementID, fieldID, idService, parentPath) {
	var list_question = document.getElementById(elementID);
	clearTimeout(time_out);
	var field = document.getElementById(fieldID);
	if (list_question) {
		var response = ajax_file(parentPath + 'ajax_list_question.php?idService=' + idService);
		if (response) {
			list_question.innerHTML = response;
			field.innerHTML = "";
			$(function () {$('.select_styling').dropkick();}); //Force 'Votre question' styling from dropkick JS
		}
	}
}
function show_fields(elementID, idService, idQuestion, parentPath) {
	document.getElementById('dk_container_question_contact').className = "dk_container dk_theme_default select_styling"; //allow to close 'Votre question' dropdown
	var list_field = document.getElementById(elementID);
	clearTimeout(time_out);
	if (list_field) {
		var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=' + idService + '&idQuestion=' + idQuestion);
		$(".contact-beware a").attr('href',parentPath + create_link('connexion_login') + '?from=contact&id_service=' + idService + '&id_question=' + idQuestion);
		if (response) {
			list_field.innerHTML = response;
			tab = new Array();
			tab[0] = new Array('alpha','contactNom','contact_field_nom');
			tab[1] = new Array('alpha','contactPrenom','contact_field_prenom');
			tab[2] = new Array('mail','contactMail','contact_field_mail');
			var i = 3;
			if( idService == 2 && idQuestion == 5 ) {
				tab[i] = new Array('alphanum','contactRefProduit','contact_field_ref_produit');
				i++;
			}
			if( idService == 14 ){
				if( idQuestion == 3 ) {
					tab[i] = new Array('phone','contactNumPortable','contact_field_num_portable');
					i++;
					tab[i] = new Array('alphanum','garantieNomProduit','nom_produit');
					i++;
				}
				else if( idQuestion == 2 ) {
					var content = ajax_file('ajax_field_active_garantie.php' );
					if(content){
						$('#select_garantieNomProduit').append( content);
						$('#field_garantieNomProduit').removeClass('cache');
						$('#field_error_garantieProduit').addClass('cache');
						$('#field_photo').addClass('cache');
						$('#field_error_garantieProduit td').empty();
					}
					else
						$('#field_garantieNomProduit').addClass('cache');
				}
			}
			if( !(idService == 3 && idQuestion == 2) ){
				tab[i] = new Array('no_empty','contactMessage','contact_field_message');
			}
		}
		else
			list_field.innerHTML = '';
	}
	$('.selectStyled').each(function(){
		$(this).selectStyled();
	});
}
function show_field_form(parentPath){
	var list_field = document.getElementById('field');
	clearTimeout(time_out);
	if (list_field) {
		var response = ajax_file(parentPath + 'ajax_field_contact.php?idService=7&idQuestion=1');
		if (response) {
			list_field.innerHTML = response;
			tabError = new Array();
			tabError[0] = new Array('alpha','contactNom','contact_field_nom');
			tabError[1] = new Array('alpha','contactPrenom','contact_field_prenom');
			tabError[2] = new Array('mail','contactMail','contact_field_mail');
			tabError[3] = new Array('alphanum','contactAdresse','contact_field_adresse');
			tabError[4] = new Array('alphanum','contactCP','contact_field_cp');
			tabError[5] = new Array('alphanum','contactVille','contact_field_ville');
			tabError[6] = new Array('alphanum','contactPays','contact_field_pays');
			$('.selectStyledFaq, .selectStyled, .selectStyledProduct').each(function(){
				$(this).selectStyled();
			});
		}
		else
			list_field.innerHTML = '';
	}
}
function show_product_commande(idCommande, parentPath){
	var list_field =  document.getElementById('field_produit_commande');
	if (idCommande) {
		var response = ajax_file(parentPath + 'ajax_field_product_commande.php?idCommande=' + idCommande);
		if (response){
			list_field.innerHTML = response;
		}
	}else{
		list_field.innerHTML = "";
		$('#field_message').addClass('cache');
		$('#field_photo').addClass('cache');
		$('#field_send').addClass('cache');
	}
}
function show_product_garantie(idCommande, idProduit, parentPath){
	if (idCommande && idProduit) {
		var response = ajax_file(parentPath + 'ajax_field_product_garantie.php?idCommande=' + idCommande + '&idProduit=' + idProduit);
		if (response){
			if(response == "Produit Retourne"){
				document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_produit_retourne');
				$('#field_message').addClass('cache');
				$('#field_photo').addClass('cache');
			}else{
				tab = new Array();
				tab[0] = new Array('alpha','contactNom','contact_field_nom');
				tab[1] = new Array('alpha','contactPrenom','contact_field_prenom');
				tab[2] = new Array('mail','contactMail','contact_field_mail');
				tab[3] = new Array('no_empty','contactMessage','contact_field_message');
				$('#field_message').removeClass('cache');
				$('#field_photo').removeClass('cache');
				document.getElementById('field_error_produit_garantie').innerHTML = "";
			}
		}else{
			document.getElementById('field_error_produit_garantie').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=contact_field_error_garantie');
			$('#field_message').addClass('cache');
			$('#field_photo').addClass('cache');
		}
	}else{
		$('#field_message').addClass('cache');
		$('#field_photo').addClass('cache');
	}
}
function generateNewBlocProd( type, idObj, parentPath, page, nb_product, return_url, no_price ) {
	var is_from_page = false;
	var $nb_prod = $( '#nb_prod' );
	if( typeof( nb_product ) != 'undefined' ){
		$nb_prod.val( nb_product );
	}
	else{
		nb_product = $nb_prod.val();
	}
	var $page = $( '#page' );
	if( typeof( page ) != 'undefined' && page != 'all' ){
		$page.val( page * nb_product );
		is_from_page = true;
	}
	var page_value = $page.val();
	var str_taille = '';
	var str_couleur = '';
	var str_obj = '';
	var str_final = '';
	if( tab_obj.length == 0 ) {
		$(".left_menu_ss_cat").find('.left_menu_obj').each(function(){
			var element = $(this);
			var id;
			if(element.hasClass("btn_select")) {
				id = element.attr('id');
				tab_obj.push(id.substring(4));
			}
		});
	}
	if( tab_color.length > 0 ) str_couleur = tab_color.join('~');
	if( tab_obj.length > 0 ) str_obj = tab_obj.join('~');
	if( tab_taille.length > 0 ) str_taille = tab_taille.join('~');
	if( tab_obj.length == 0 && all_obj_id != '' )
		str_obj = all_obj_id;
	str_final += '&couleur=' + str_couleur;
	str_final += '&taille=' + str_taille;
	if( !no_price && document.getElementById('amount1').value != document.getElementById('amount2').value ) {
        str_final += '&prix=' + document.getElementById('amount1').value + '~' + document.getElementById('amount2').value;
    }
	if( document.getElementById('page').value == 'all' )
		all_product = true;
	else
		all_product = false;
	$("#list_item").removeClass('no_result');
	var type_nav = document.getElementById( 'type_nav' ),
		all_product = !type_nav || type_nav.value == 'all';
	var $list_item = $( '#list_item' );

    if (return_url)
    {
        return parentPath + 'ajax_reload_products.php?page=' + page_value + '&type=' + type + '&parentPath=' + parentPath + '&idObj=' + idObj + '&nb_prod=' + nb_product + '&is_page=' + is_from_page + str_final;
    }
	else if( all_product == true ) {
		var $wrapper = $( '#wrapper_resultat' ),
		$window = $( window );
		$( ".more_content" ).remove();
		if( page == 'all' ) {
			var hash;
			if( ( hash = location.hash && location.hash.match( /i=[0-9]+/g )[0] ) || page_value > 0 ){
				var marginTop = 10,
					$elem = hash? $( '#' + hash.replace( '=', '' ) ).children(): $list_item,
					offset = $elem.offset(),
					top = offset && offset.top - marginTop;
				window.setTimeout(function(){
					$window.scrollTop( top );
				}, 0 );
			}
		}
		else{
			$list_item.html( '<p id="loading"><img style="margin-top:35px;margin-right:10px;" src="' + parentPath + 'img/ajaxLoader.gif" alt="Chargement en cours..." width="25" height="25" /><br /><span>Chargement...</span></p>' );
			var response = ajax_file( parentPath + 'ajax_reload_products.php?page=' + page_value + '&type=' + type + '&parentPath=' + parentPath + '&idObj=' + idObj + '&nb_prod=' + nb_product + '&is_page=' + is_from_page + str_final );
			var tab_response = response.split('><><');
			if( tab_response[0] != '' ) {
				var bloc_photo = tab_response[0],
					nb_articles = tab_response[1];
				$list_item.html( bloc_photo );
			}
			else{
				var nb_articles = 0;
				$list_item.html( ajax_file( parentPath + 'ajax_sprintf.php?arg1=no_response_search' ) );
				$list_item.addClass( 'no_result' );
			}
			document.getElementById('nb_articles').innerHTML = nb_articles;
			document.getElementById( 'totalElems' ).value = nb_articles;
		}
	}
	else {
		alert('fd');
		var response = ajax_file(parentPath + 'ajax_reload_products.php?page=' + document.getElementById('page').value + '&type=' + type + '&parentPath=' + parentPath + '&idObj=' + idObj + '&nb_prod=' + document.getElementById('nb_prod').value + '&is_page=' + is_from_page + str_final );
		var tab_response = response.split('><><');
	}
	return tab_response;
}
function generateNewBlocSearch( parameters, page, parentPath, from_init, nb_product ) {
	var is_from_page = false;
	var $nb_prod = $( '#nb_prod' );
	if( typeof( nb_product ) != 'undefined' ){
		$nb_prod.val( nb_product );
	}
	else{
		nb_product = $nb_prod.val();
	}
	var $page = $( '#page' );
	if(typeof(page) != 'undefined' && page != 'all' ) {
		$page.val( page * nb_product );
		is_from_page = true;
	}
	var page_value = $page.val();
	var str_taille = '';
	var str_couleur = '';
	var str_obj = '';
	var str_final = parameters;
	if( tab_obj.length == 0 ) {
		$(".left_menu_ss_cat .btn_expand_off").parent().next().find('.left_menu_obj').each(function(){
			if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
				($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
				$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px' ||
				$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
				$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%' ||
				$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%'
			) {
				id = $(this).attr('id');
				tab_obj.push(id.substring(4));
			}
		});
	}
	if( tab_color.length > 0 ) str_couleur = tab_color.join('~');
	if( tab_obj.length > 0 ) str_obj = tab_obj.join('~');
	if( tab_taille.length > 0 ) str_taille = tab_taille.join('~');
	str_final += '&couleur=' + str_couleur;
	str_final += '&obj=' + str_obj;
	str_final += '&taille=' + str_taille;
	if( typeof(from_init) == 'undefined' )
		str_final += '&prix=' + document.getElementById('amount1').value + '~' + document.getElementById('amount2').value;
	if( document.getElementById('page').value == 'all' )
		all_product = true;
	else
		all_product = false;
	var type_nav = document.getElementById( 'type_nav' ),
		all_product = !type_nav || type_nav.value == 'all';
	var $list_item = $( '#list_item' );
	if( all_product == true ) {
		var $wrapper = $( '#wrapper_resultat' ),
		$window = $( window );
		if( document.getElementById('pagination_haut') )
			document.getElementById('pagination_haut').innerHTML = '';
		if( document.getElementById('pagination_bas') )
			document.getElementById('pagination_bas').innerHTML = '';
		$( ".more_content" ).remove();
		if( page == 'all' ) {
			ok_loading = false;
			var hash;
			if( ( hash = location.hash && location.hash.match( /i=[0-9]+/g )[0] ) || page_value > 0 ){
				var marginTop = 10,
					$elem = hash? $( '#' + hash.replace( '=', '' ) ).children(): $list_item,
					offset = $elem.offset(),
					top = offset && offset.top - marginTop;
				window.setTimeout(function(){
					$window.scrollTop( top );
				}, 0 );
			}
		}
		else{
			$list_item.html( '<p id="loading"><img style="margin-top:35px;margin-right:10px;" src="' + parentPath + 'img/ajaxLoader.gif" alt="Chargement en cours..." width="25" height="25" /><br /><span>Chargement...</span></p>' );
			var response = ajax_file( parentPath + 'ajax_reload_search.php?' + str_final + '&is_page=' + is_from_page + '&parentPath=' + parentPath + '&nbProducts=' + nb_product + '&page=' + page_value );
			var tab_response = response.split('><><');
			if( tab_response[0] != '' ) {
				var bloc_photo = tab_response[0],
				nb_articles = tab_response[1];
				$list_item.html( bloc_photo );
			}
			else{
				var nb_articles = 0;
				$list_item.html( ajax_file( parentPath + 'ajax_sprintf.php?arg1=no_response_search' ) );
			}
			document.getElementById('nb_articles').innerHTML = nb_articles;
			document.getElementById( 'totalElems' ).value = nb_articles;
		}
	}
	else {
		ok_loading = false;
		$(".more_content").remove();
		document.getElementById('list_item').innerHTML = '<p id="loading"><img style="margin-top:35px;margin-right:10px;" src="' + parentPath + 'img/ajaxLoader.gif" alt="Chargement en cours..." width="25" height="25" />Chargement...<span></span></p>';
		var response = ajax_file(parentPath + 'ajax_reload_search.php?' + str_final + '&is_page=' + is_from_page + '&parentPath=' + parentPath + '&nbProducts='+ document.getElementById('nb_prod').value + '&page=' + document.getElementById('page').value );
		var tab_response = response.split('><><');
		if( tab_response[0] != '' ) {
			var bloc_photo = tab_response[0];
			document.getElementById('list_item').innerHTML = bloc_photo;
			$('#wrapper_visus_produit').ready(function(){
				$('.photo_produit_horiz').each(function(){
					var obj = $(this);
					var img = $('img',obj);
					img.load(function(){
						var tImgH = img.height();
						var tImgW = img.width();
						obj.css({position:'relative'});
						img.css({position : 'absolute', top:'50%', left : '50%', marginLeft : -(tImgW/2) , marginTop : -(tImgH/2)});
					});
				});
			});
			$(".slider_product_wrapper").each(function(){
				$(this).hover(function(){
					$(this).children('.visu_product_slider').children('.visu_product_slider_hover').show();
				}, function(){
					$(this).children('.visu_product_slider').children('.visu_product_slider_hover').hide();
				});
			});
			var nb_articles = tab_response[1];
			document.getElementById('nb_articles').innerHTML = nb_articles;
			var nav = tab_response[2];
			var nav_bas = tab_response[3];
			document.getElementById('pagination_haut').innerHTML = nav;
			document.getElementById('pagination_bas').innerHTML = nav_bas;
			var nb_prod = tab_response[4];
			if(document.getElementById('nb_prod_numeric')) document.getElementById('nb_prod_numeric').innerHTML = nb_prod;
			if(document.getElementById('label_nb_prod_numeric')) {
				if( nb_prod == '' )
					document.getElementById('label_nb_prod_numeric').style.display = 'none';
				else
					document.getElementById('label_nb_prod_numeric').style.display = 'block';
			}
			if(typeof(from_init) != 'undefined' && (typeof(page) == 'undefined' ||  page==false)) {
				var prix_min = tab_response[5];
				var prix_max = tab_response[6];
				$("#amount1").val(prix_min);
				$("#amount2").val(prix_max);
				$("#slider-range").slider( "option" , { min:parseInt(prix_min),max:parseInt(prix_max),values:[parseInt(prix_min),parseInt(prix_max)]} );
				min_price = prix_min;
				max_price = prix_max;
				$(".ui-slider-range").width("100%");
				$(".ui-slider-range").css('left','0%');
				$(".ui-slider-range").next().css('left','0%');
				$(".ui-slider-range").next().next().css('left','100%');
				var color = tab_response[7];
				var tab_color_tmp = color.split('~');
				var cnt_color = tab_color_tmp.length;
				var size = tab_response[8];
				var tab_size_tmp = size.split('~');
				var cnt_size = tab_size_tmp.length;
				if( tab_color_tmp[0] != '' ) {
					var str_color = '';
					for( var j=0 ; j<cnt_color ; j++ ) {
						var detail_color = tab_color_tmp[j].split('|');
						var class_color = 'btn_select_off';
						if( tab_color.length > 0 ) {
							if( in_array(detail_color[0],tab_color) )
								class_color = 'btn_select';
						}
						str_color += '<div id="couleur_' + detail_color[0] + '" class="left_menu_obj '+class_color+'"><div class="f_left"></div><div class="f_left txt_menu">' + detail_color[1] + ' (' + detail_color[2] + ')</div></div>';
					}
					$("#bloc_couleur .selected_options").html(str_color);
					$("#bloc_couleur .left_menu_obj").mousedown(function(){
						if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
							($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px'
						)
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -26px');
						else
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -13px');
					});
					$("#bloc_couleur .left_menu_obj").mouseup(function(){
						tab_color = new Array();
						tab_obj = new Array();
						if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-13px' ) ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -13px' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -13px'
						)
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -39px');
						else
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px 0px');
						$("#bloc_couleur .left_menu_obj").each(function(){

							if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
								($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%'
							) {
								id = $(this).attr('id');
								tab_color.push(id.substring(8));
							}
						});
						$(".left_menu_ss_cat .btn_expand_off").parent().next().find('.left_menu_obj').each(function(){
							if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
								($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%'
							) {
								id = $(this).attr('id');
								tab_obj.push(id.substring(4));
							}
						});
						generateNewBlocSearch( parameters, page, parentPath );
					});
				}
				else
				{
					$("#bloc_couleur .selected_options").html('');
				}
				if( tab_size_tmp[0] != '' ) {
					var str_size = '';
					for( var j=0 ; j<cnt_size ; j++ ) {
						var detail_size = tab_size_tmp[j].split('|');
						var class_size = 'btn_select_off';
						if( tab_taille.length > 0 ) {
							if( in_array(detail_size[0],tab_taille) )
								class_size = 'btn_select';
						}
						str_size += '<div id="taille_' + detail_size[0] + '" class="left_menu_obj '+class_size+'"><div class="f_left"></div><div class="f_left txt_menu">' + detail_size[1] + '</div></div>';
					}
					$("#bloc_taille .selected_options").html(str_size);
					$("#bloc_taille .left_menu_obj").mousedown(function(){
						if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
							($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px'
						)
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -26px');
						else
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -13px');
					});
					$("#bloc_taille .left_menu_obj").mouseup(function(){
						tab_taille = new Array();
						tab_obj = new Array();
						if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-13px' ) ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -13px' ||
							$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -13px'
						)
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px -39px');
						else
							$(this).find(".btn_select,.btn_select_off").css('background-position','0px 0px');
						$("#bloc_taille .left_menu_obj").each(function(){
							if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
								($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%'
							) {
								id = $(this).attr('id');
								tab_taille.push(id.substring(7));
							}
						});
						$(".left_menu_ss_cat .btn_expand_off").parent().next().find('.left_menu_obj').each(function(){
							if( ($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == 'bottom' ) ||
								($.browser.msie && $(this).find(".btn_select,.btn_select_off").css('background-position-y') == '-39px' ) ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% -39px' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0% 100%' ||
								$(this).find(".btn_select,.btn_select_off").css('background-position') == '0px 100%'
							) {
								id = $(this).attr('id');
								tab_obj.push(id.substring(4));
							}
						});
						generateNewBlocSearch( parameters, page, parentPath );
					});
				}
				else {
					$("#bloc_taille .selected_options").html('');
					$('#list_item').html(ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search'));
				}
			}
		}
		else {
			document.getElementById('list_item').innerHTML = ajax_file(parentPath + 'ajax_sprintf.php?arg1=no_response_search');
			document.getElementById('pagination_haut').innerHTML = '';
			document.getElementById('pagination_bas').innerHTML = '';
		}
	}
}
function show_orders_by_type(elementID, type, parentPath) {
	var i = 1;
	while( document.getElementById('onglet_'+i+'_mid') ) {
		if( type == i ) {
			$("#onglet_"+i+"_left").attr('class','left-on');
			$("#onglet_"+i+"_mid").attr('class','mid-on');
			$("#onglet_"+i+"_right").attr('class','right-on');
		}
		else {
			$("#onglet_"+i+"_left").attr('class','left-off');
			$("#onglet_"+i+"_mid").attr('class','mid-off');
			$("#onglet_"+i+"_right").attr('class','right-off');
		}
		i++;
	}
	var container = document.getElementById(elementID);
	if (container) {
		var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type);
		if (response) {
			container.innerHTML = response;
			return true;
		}
		else{
			container.innerHTML = '';
			return false;
		}
	}
	else
		return false;
}
function show_message(mId, hide) {
	if (hide == 0) {
		var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId);
		if (ajax_content) {
			document.getElementById("message_"+ mId).innerHTML = ajax_content;
			document.getElementById("message_"+ mId).onclick = '';
			document.getElementById("message_"+ mId).style.cursor = 'default';
		}
	} else {
	var ajax_content = ajax_file('ajax_show_messages.php?mId='+ mId + '&hide=1');
		if (ajax_content) {
			document.getElementById("message_" + mId).innerHTML = ajax_content;
			document.getElementById("message_" + mId).onclick = "show_message("+mId+",0);";
			document.getElementById("message_" + mId).style.cursor = 'pointer';
		}
	}
}
var timer;
function newNewsletterFromCmpid() {
	var flag = verif_mail( 'email_inscription_home','login_email' );
	if( flag ) {
		flag = ajax_file(path_relative_root+'ajax_email_newsletter_exist.php?mail='+ document.getElementById('email_inscription_home').value);
		var elementID = 'email_inscription_home';
		var objElement = document.getElementById(elementID);
		var objLabel = document.getElementById(elementID + 'Label');
		var erreurText = document.getElementById('erreur_msg');
		if( flag === '1' ) {
			flag = false;
		}
		else {
			flag = true;
		}
		if( !flag ) {
			erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path+'ajax_sprintf.php?arg1=login_email') + ' :</p>' + 'Cet email est déjà inscrit à la newsletter';
			objElement.className = 'inputErr';
			objLabel.className = 'labelErr';
		}
		else {
			objElement.className = 'inputForm';
			objElement.style.border="1px solid #CCCCCC";
			objLabel.className = 'labelForm';
		}
	}
	if( flag ) {
		clearTimeout(timer);
		document.getElementById("bloc_erreur").style.display = 'none';
	}else{
		var val = document.getElementById('erreur_msg').innerHTML;
		var elem_img = document.getElementById("img_error_popup");
		$("#img_error_popup").css('display','inline-block');
		elem_img.setAttribute('onmouseover',function(){OverPopup(this,val,'visible','cmpid_adwords');});
		elem_img.onmouseover=function(){
			OverPopup(this,val,'visible','cmpid_adwords');
		};
		elem_img.setAttribute('onmouseout',function(){OverPopup(this,val,'none','cmpid_adwords');});
		elem_img.onmouseout=function(){OverPopup(this,val,'none','cmpid_adwords');};
		elem = document.getElementById('email_inscription_home');
		elem.style.border="1px solid #EA6195";
		pos = getPos(elem_img);
		document.getElementById('bloc_erreur').style.position = 'absolute';
		var nav = navigator.appVersion;
		if(!jQuery.browser.msie) {
			$("#bloc_erreur").fadeIn(400);
		}
		else {
			document.getElementById("bloc_erreur").style.display = 'block';
		}
		var clone = $('#bloc_erreur').clone(),
		c_pos = {
			top : $('#img_error_popup').offset().top - $('#img_error_popup').offsetParent().offset().top - $('#bloc_erreur').height() + 4,
			left : $('#img_error_popup').offset().left - $('#img_error_popup').offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($('#img_error_popup').width() * 2) + 6
		};
		$('#bloc_erreur').remove();
		$('#img_error_popup').after(clone);
		$('#bloc_erreur').find('td:nth-child(2)').children('img').css({
			'float' : 'right',
			'margin' : '0 7px'
		});
		$('#bloc_erreur').css({
			'position' : 'absolute',
			'top' : c_pos.top,
			'left' : c_pos.left+271,
			'z-index' : '99999',
			'opacity' : '1'
		});
		window.clearTimeout(timer);
		timer = setTimeout("closeInfobulle('bloc_erreur')",5000);
	}
	return flag;
}
function checkAndAddMail() {
	if( newNewsletterFromCmpid() ) {
		$("#btn_conf_cmpid").css('display','none');
		$("#loader_popup").css('display','block');
		response = ajax_file(path_relative_root+'ajax_add_email_newsletter.php?mail='+ document.getElementById('email_inscription_home').value);
		if( response == 1 ) {
			$("#formu_inscription_news").fadeOut(750,function() {
				$("#conf_inscription_news").fadeIn(750);
			});
			document.getElementById('shad').setAttribute('onclick',function(){ close_popup('popup_inscription_home'); });
			document.getElementById('shad').onclick=function(){ close_popup('popup_inscription_home');}
		}
	}
}
function ajaxDeconnect(path_relative_root){
    var provenance = window.location.pathname,
    cpt_client = new RegExp(/(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/),
    response;
    provenance = provenance.substr(provenance.lastIndexOf('/'), provenance.lastIndexOf('.'));
	$.ajax({
	    type: 'POST',
	    url: 'ajax_deconnect.php',
	    success: function( response ){
			parent.frame_nav.navigate({ top : 'index.php', bot : create_link('order_basket') });
			hideLoader();
	    },fail:function(){
			alert('Erreur lors de la déconnexion.');
			hideLoader();
		}
	});
	return false;
}
function addToWishlistShoppingcart() {

	$.ajax({
		url : path_relative_root + 'ajax_cart_to_wishlist.php',
		type : 'post',
		success : function (res) {

	        $('.btn_top_wishlist .gris').html('(x' + res.result.wishlist_size + ')');
	        $('#addToWishlistShoppingcart').removeClass('addToWishlistShoppingcart').addClass('existsToWishlistShoppingcart');
	        $('#addToWishlistShoppingcart').html('<p>' + res.result.button_label + '</p>');

	        parent.frame_nav.navigate({ top : create_link('wishlist') });
		}
	});
}
function deletePromoCode() {
	$.ajax({
	    type: 'get',
	    url: path_relative_root+create_link('delete_promo_code'),
	    success: function( response ){
	        location.href = path_relative_root + create_link('order_kiala');
	    }
	});
}
function display_lightbox_specifique() {
	var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php' );
	if ( html ) {
		var $lighbox = $( '#lightbox_achat_express' );
		var $shad = $( '#shad' );
		$lighbox
		.html( '<div style="position: relative">'+ html +'</div>' )
		.appendTo( 'body' )
		.show()
		;
		$shad
		.show()
		.unbind( 'click.achat_express' )
		.bind( 'click.achat_express', function(){
		hideLightboxGamme();
		$("#alertbox").remove();
		})
		;
	}
}
function add_produit_specifique(product_id, color_bloc_id, size_bloc_id) {
	var color_id = 0;
	if ( document.getElementById(color_bloc_id ) )
		color_id = document.getElementById( color_bloc_id ).value;
	var size_id = 0;
	if ( document.getElementById(size_bloc_id ) )
		size_id = document.getElementById( size_bloc_id ).value;
	if(document.getElementById(size_bloc_id ) && size_id == ''){
		alert(size_id);
		var taille_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_size');
		$("#label_alert").html(taille_label);
		has_errors = true;
		var $alertbox = $( '#alertbox' );
		$alertbox
			.appendTo( 'body' )
			.show()
			.css('z-index','10000')
		;
		if(document.getElementById('lightbox_achat_express')) {
			$alertbox.css({
				left:	'52%',
				top:	'490px',
				position:	'fixed'
			});
		}
		addEvent(shad,'click',closeMultiShad);
		$("#bloc_btn_loader").hide();
		document.getElementById("bloc_btn_active").style.display="block";
	}
	else if(document.getElementById(color_bloc_id ) && color_id == ''){
		var couleur_label = ajax_file(path_relative_root+'ajax_sprintf.php?arg1=product_color');
		$("#label_alert").html(couleur_label);
		has_errors = true;
		var $alertbox = $( '#alertbox' );
		$alertbox
			.appendTo( 'body' )
			.show()
			.css('z-index','10000')
		;
		if(document.getElementById('lightbox_achat_express')) {
			$alertbox.css({
				left:	'52%',
				top:	'490px',
				position:	'fixed'
			});
		}
		addEvent(shad,'click',closeMultiShad);
		$("#bloc_btn_loader").hide();
		document.getElementById("bloc_btn_active").style.display="block";
	} else {
		var retour = ajax_file(path_relative_root + 'ajax_add_produit_specifique.php?product_id=' + product_id + '&color_id=' + color_id + '&size_id=' + size_id );
		if ( retour == 'continue' ) {
			var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php' );
			if ( html ) {
				$("#bloc_btn_loader").hide();
				document.getElementById("bloc_btn_active").style.display="block";
				$( '#lightbox_achat_express' ).html( '<div style="position: relative">'+ html +'</div>' );
				$("#bloc_btn_loader").hide();
				document.getElementById("bloc_btn_active").style.display="block";
			}
		} else {
			location.href = path_relative_root + create_link('order_basket');
		}
	}
}
function checkActiveGarantie(reponse){
	if(reponse == 0){
		$('#field_error_garantieProduit').removeClass('cache');
		$('#field_error_garantieProduit td').empty();
		$('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_field_active_garantie' ));
		$('#select_garantieNomProduit').empty();
		$('#field_garantieNomProduit').addClass('cache');
		$('#field_photo').addClass('cache');
		$('#field_message').addClass('cache');
		$('#field_send').addClass('cache');
	}else{
		var dateJ = $('#contactDateCommandeJ').val();
		var dateM = $('#contactDateCommandeM').val();
		var dateA = $('#contactDateCommandeA').val();
		var content = ajax_file('ajax_field_active_garantie.php?dateJ=' + dateJ + "&dateM=" + dateM + "&dateA=" + dateA );
		if(content){
			$('#select_garantieNomProduit').append( content);
			$('#field_garantieNomProduit').removeClass('cache');
			$('#field_error_garantieProduit').addClass('cache');
			$('#field_error_garantieProduit td').empty();
		}else{
			$('#field_error_garantieProduit td').empty();
			$('#field_error_garantieProduit td').append(ajax_file( 'ajax_sprintf.php?arg1=contact_error_garantieNomProduit' ));
		}
	}
}
function completeCity( elem, val ) {
	if( val.length >= 4 ) {
		$.ajax({
			type: 'post',
			data: {cp : val},
			url: path_relative_root+'ajax_autocomplete_city.php',
			success: function( response ){
				globalString = $.parseJSON(response);
				if( response != "" ) {
					var lengthSting = globalString.length;
					var value_return = '<select onchange="' + event + '" id="select_list_' + type + '" name="select_list_' + type + '"><option value="">Choisissez</option>';
					for (var i = 0; i < lengthSting; i++) {
						value_return += '<option value="' + globalString[i] + '">' + globalString[i] + '</option>';
					}
					value_return += '</select>';
					$( "#" + elem ).html(value_return);
					$('.selectStyled').each(function(){
						$(this).selectStyled();
					});
				}
			}
		});
	}
}
function ajax_RemoveBasketProd(basket_id, prod_id, qte){
	$.ajax({
		type: 'post',
		data: {
			basket_id: basket_id
			,prod_id: prod_id
			,qte: qte
		},
		url: path_relative_root+'ajax_removeBasketProd.php',
		success: function( response ){
			try{
				response = $.parseJSON( response );
			}
			catch( e ){
				console.error( 'parseJSON' );
				return;
			}
			var datas = response.datas;
			var $html = $( datas.html );
			$('#cart_top').html($html);
		}
	});
}
function openCloseBloc( elem, id_bloc, class_bloc, is_detail, url_ajax, txt_open, txt_close, class_btn ) {
	var data = {};
	if( is_detail ) {
		var id = elem.attr('id');
		var type = 1;
		elemId = id.substring(id.indexOf('_')+1);
		if( class_bloc == 'order_detail' ) {
			type = $('#order_type_'+elemId).val();
			data.cdeId = elemId;
		}
		else {
            data.mId = elemId;
        }
		data.type = type;
		id_bloc = id_bloc + '_' + elemId;
        id_wrapper = 'wrapper' + '_' + elemId;
	}
    else
    {
        id_wrapper = id_bloc.replace('bloc', 'wrapper');
    }
	if(!(elem).hasClass('actif')) {
		if( is_detail ) {
			$('.'+class_bloc).not($('#'+id_bloc)).slideUp();
			$('.'+class_btn).not(elem).removeClass('actif');
			$('.'+class_btn).not(elem).text(txt_open);
		}
		if( $('#'+id_bloc).html() == '' ) {
			if( url_ajax != '' ) {
				$.ajax({
					type: 'post',
					url: path_relative_root+url_ajax,
					data : data,
					success: function( response ){
						$('#'+id_bloc).html(response).slideDown();
                        $('#' + id_wrapper).addClass('bloc-opened');
					}
				});
			}
			else {
                $('#' + id_bloc).slideDown();
            }
		}
		else {
            $('#' + id_bloc).slideDown();
        }
	    (elem).addClass('actif');
        $('#' + id_wrapper).addClass('bloc-opened');
	    (elem).text(txt_close);
	} else {
    	$('.'+class_bloc).slideUp();
        (elem).removeClass('actif');
        $('#' + id_wrapper).removeClass('bloc-opened');
        (elem).text(txt_open);
    }
}
function sendMessage(id) {
	if( $('#msg_txt_'+id).val() != '' ) {
		$.ajax({
			type: 'post',
			data: { mId: id, type: 1, message: $("#msg_txt_"+id).val(), act:"rep" },
			url: path_relative_root+create_link('message_detail'),
			success: function( response ){
				if( response == '1' ) {
					$('#msg_conf_'+mId).html(message_send);
					$('#msg_conf_'+mId).show();
				}
				else {
					$('#msg_conf_'+mId).html(message_fail);
					$('#msg_conf_'+mId).show();
				}
			}
		});
	}
	else
		$('#msg_conf_'+mId).hide();
}
function cancelMessage(id) {
    $('#msg_txt_'+id).val('');
}

(function () {

    'use strict';

    function parseArgs(args) {

        var obj = {},
            inc = 0,
            len = args.length;

        for (inc; inc < len; inc += 1) {

            obj['arg' + inc] = args[inc];
        }

        return obj;
    }

    function create_link() {

        if (typeof arguments[0] == 'object') {
            var o_data = arguments[0];
        }
        else {
            var o_data = parseArgs(arguments);
        }
        var s_resp = arguments[0];

        $.ajax({
            url: path_relative_root + 'ajax_createlink.php',
            type: 'get',
            data: o_data,
            async: false,
            success: function (res) {

                s_resp = res;
            }
        });

        return s_resp;
    }

    window.create_link = create_link;
}());