(function (proto) {

    'use strict';

    var ERROR = {
            BAD_CLASS : 'Invalid classes object provided'
        },
        clas,
        resp,
        i;

    ERROR = Object.freeze(ERROR);

    // Check wether an element has the given class(es) or not
    proto.hasClass = function (cla, strict) {

        resp = false;

        if (strict) {

            resp = (cla.trim() === this.className.trim());
        } else {

            resp = (this.className.indexOf(cla) !== -1);
        }

        return resp;
    };

    proto.hasClasses = function (cla, strict) {

        clas = this.className.trim().split(' ');
        resp = false;

        if (cla.constructor === String) {

            cla = cla.split(' ');
        }

        if (cla.constructor === Array) {

            if (!strict || (cla.length === clas.length)) {

                for (i = 0; i < cla.length; i += 1) {

                    resp = this.hasClass(cla[i], false);

                    if (!resp) {

                        break;
                    }
                }
            }
        } else {

            window.console.error(ERROR.BAD_CLASS);
        }

        return resp;
    };

    // Add one or several classes
    proto.addClass = function (cla) {

        if (this.className.indexOf(cla) === -1) {

            if (this.className === '') {

                this.className = cla.trim();
            } else {

                this.className += ' ' + cla;
                this.className = this.className.trim();
            }
        }

        return this;
    };

    proto.addClasses = function (cla) {

        if (cla.constructor === String) {

            cla = cla.split(' ');
        }

        if (cla.constructor === Array) {

            for (i = 0; i < cla.length; i += 1) {

                this.addClass(cla[i]);
            }
        } else {

            window.console.error(ERROR.BAD_CLASS);
        }

        return this;
    };

    // Remove one or several classes
    proto.delClass = function (cla) {

        this.className = this.className.replace(cla, '').trim();

        if (this.className === '') {

            this.removeAttribute('class');
        }

        return this;
    };

    proto.delClasses = function (cla) {

        if (cla.constructor === String) {

            cla = cla.split(' ');
        }

        if (cla.constructor === Array) {

            for (i = 0; i < cla.length; i += 1) {

                this.delClass(cla[i]);
            }
        } else {

            window.console.error(ERROR.BAD_CLASS);
        }

        return this;
    };

    // Toggle one or several classes
    proto.tglClass = function (cla) {

        if (this.className.indexOf(cla) === -1) {

            if (this.className === '') {

                this.className = cla.trim();
            } else {

                this.className += ' ' + cla;
                this.className = this.className.trim();
            }
        } else {

            this.className = this.className.replace(cla, '').trim();

            if (this.className === '') {

                this.removeAttribute('class');
            }
        }

        return this;
    };

    proto.tglClasses = function (cla) {

        if (cla.constructor === String) {

            cla = cla.split(' ');
        }

        if (cla.constructor === Array) {

            for (i = 0; i < cla.length; i += 1) {

                this.tglClass(cla[i]);
            }
        } else {

            window.console.error(ERROR.BAD_CLASS);
        }

        return this;
    };
}(window.Element.prototype));
