(function (root) {

	'use strict';

	// Combines getElementById and querySelector to get elements
	function get(id) {

		var elm;

		if (id.substr(0, 1).match(/(?:\.|\#)/)) {

			elm = root.querySelector(id);
		} else {

			elm = root.getElementById(id);
		}

		return elm;
	}

	// Constructor
	function FS_Show(id, delay) {

		this.slider = get(id);
		this.delay = delay || 5000;

		// Fail and return false if given element doesn't exist
		return (this.slider) ? this.init() : false;
	}

	// Initiation routine
	FS_Show.prototype.init = function () {

		var that = this;

		this.slides = this.slider.getElementsByClassName('fs_show_view');
		this.max = this.slider.childElementCount;

		this.table = [this.max - 1, 0, 1];

		if (typeof(this.slides[this.table[0]]) != 'undefined') {
			(this.slides[this.table[0]]).addClass('prev');
		}
		if (typeof(this.slides[this.table[1]]) != 'undefined') {
			(this.slides[this.table[1]]).addClass('current');
		}
		if (typeof(this.slides[this.table[2]]) != 'undefined') {
			(this.slides[this.table[2]]).addClass('next');
		}

		// Load the second image (first one is loaded with the page)
		this.loadImg(1);

		return that;
	}

	// Image loading routine
	FS_Show.prototype.loadImg = function (index) {

		var that, time, slide, path, img;

		that = this;

		// Loading start time
		time = new Date().getTime();

		slide = this.slides[index];

		if (slide) {

			path = slide.getAttribute('data-img');
			img = new Image();

			img.addEventListener('load', function () {

				slide.setAttribute('data-loaded', true);
				slide.appendChild(this);

				// Loading end time & time offset
				time = new Date().getTime() - time;
				time = that.delay - time;

				if (time > 0) {

					// If the image loaded faster than set delay, wait a moment...
					window.setTimeout(function () {

						FS_Show.prototype.goToNext.call(that, index);
					}, time);
				} else {

					// Else, show image
					that.goToNext(index);
				}
			});

			// Suppress corrupted images
			img.addEventListener('error', function () {

				that.slider.removeChild(slide);

				that.slides = that.slider.getElementsByClassName('fs_show_view');
				that.max = that.slider.childElementCount;

				that.loadImg(index);
			});

			img.className = 'fs_img_content';
			img.src = path;
		}
	}

	// Slide to next image
	FS_Show.prototype.goToNext = function (index) {

		var that = this;

		(this.slides[this.table[0]]).delClass('prev');

		(this.slides[this.table[1]]).tglClasses(['current', 'prev']);
		(this.slides[this.table[2]]).tglClasses(['next', 'current']);

		this.table[0] = this.table[1];
		this.table[1] = this.table[2];
		this.table[2] = (this.table[2] + 1 < this.max) ? this.table[2] + 1 : this.table[2] - this.max + 1;

		(this.slides[this.table[2]]).addClass('next');

		// While we're loading images...
		if (index) {

			// If image is not the last one
			if (index + 1 < this.max) {

				// Wait for the animation to finish then load next image
				window.setTimeout(function () {

					FS_Show.prototype.loadImg.call(that, index + 1);
				}, 2600);
			} else {

				// Set an infinite loop to actually run the slideshow
				window.setInterval(function () {

					FS_Show.prototype.goToNext.call(that);
				}, this.delay);
			}
		}
	}

	// Global namespace hook
	window.FS_Show = FS_Show;
}(window.document));
