(function () {

	"use strict";

	var ProdSlider = function (wrapper) {

		this.wrapper = wrapper;
		this.items = this.wrapper.children;
		this.step = 0;
		this.offset = 0;
		this.scrollMax = Math.min(0, this.wrapper.offsetWidth - this.wrapper.scrollWidth);
	};

	ProdSlider.prototype.goToNext = function (evt) {

		var that = this;
		var elm = this.items[this.step];
		var elmWidth, newOffset, delay;

		this.items = this.wrapper.children;
		this.scrollMax = Math.min(0, this.wrapper.offsetWidth - this.wrapper.scrollWidth + this.offset);

		if (evt) {

			evt.preventDefault();
		}

		if (elm) {

			elmWidth = this.items[this.step].offsetWidth;
			newOffset = this.offset - elmWidth;

			if (newOffset >= this.scrollMax) {

				this.step += 1;
				this.offset = newOffset;

				Array.prototype.slice.call(this.items).forEach(function (elm, idx) {

					delay = 625 * (idx / 10);

					setTimeout(function () {

						elm.style.left = that.offset + "px";
					}, delay);
				});
			}
		}
	};

	ProdSlider.prototype.goToPrev = function (evt) {

		var that = this;
		var elm = this.items[this.step - 1];
		var elmWidth, newOffset, delay;

		this.items = this.wrapper.children;
		this.scrollMax = Math.min(0, this.wrapper.offsetWidth - this.wrapper.scrollWidth + this.offset);

		if (evt) {

			evt.preventDefault();
		}

		if (elm) {

			elmWidth = this.items[this.step - 1].offsetWidth;
			newOffset = this.offset + elmWidth;

			if (newOffset <= 0) {

				this.step -= 1;
				this.offset = newOffset;

				Array.prototype.slice.call(this.items).forEach(function (elm, idx) {

					delay = 625 * (idx / 10);

					setTimeout(function () {

						elm.style.left = that.offset + "px";
					}, delay);
				});
			}
		}
	};

	window.ProdSlider = ProdSlider;
}());
