/**
 * Touch Scroller - A Drag & Drop Content Scroller
 *
 * Uses element.js
 */
(function () {

    'use strict';

    var TouchScroller = function (elm_id) {

        var that = this,
            load = document.getElementById('filter_loader');

        if (document.getElementById(elm_id)) {

            this.elm_slider = document.getElementById(elm_id);
            this.is_grabbed = false;
            this.is_moving = false;
            this.has_moved = false;
            this.touch_orig = 0;
            this.grab_origin = 0;

            this.tracker = null;

            this.slides = this.elm_slider.getElementsByClassName('cadre1_horiz');

            this.nbr_slide = this.slides.length;
            this.max_width = 340 * Math.ceil(this.nbr_slide / 2);
            this.max_scroll = ((1295 - this.max_width) < 0) ? (1295 - this.max_width) : 0;

            this.mv_origin = 0;
            this.mv_offset = 0;

            this.pc_offset = 0;

            this.cur_page = 0;
            this.max_page = Math.ceil(this.max_width / (340 * 4)) - 1;

            this.elm_slider.style.position = 'relative';
            this.elm_slider.style.left = 0;
            this.elm_slider.style.width = this.max_width + 'px';

            this.elm_slider.addEventListener('mousedown', function (evt) {

                evt.preventDefault();

                TouchScroller.prototype.grab.call(that, evt);
            });

            window.addEventListener('mouseup', function (evt) {

                TouchScroller.prototype.release.call(that, evt);
            });

            window.addEventListener('mousemove', function (evt) {

                TouchScroller.prototype.move.call(that, evt);
            });

            if (load && load.style.display === 'block') {

                $(load).fadeOut();
            }
        } else {

            console.error('Element %s could not be found in DOM', elm_id);

            that = false;
        }

        return that;
    };

    TouchScroller.prototype.setTracker = function (tracker) {

        this.tracker = tracker;
    };

    TouchScroller.prototype.grab = function (evt) {

        evt.preventDefault();

        this.is_grabbed = true;
        this.touch_orig = evt.pageX;
        this.grab_origin = evt.pageX;
        this.has_moved = false;

        this.elm_slider.classList.add('grabbed');

        return evt;
    };

    TouchScroller.prototype.release = function (evt) {

        var that = this,
            dist = parseFloat(this.elm_slider.style.left);

        evt.preventDefault();

        this.is_grabbed = false;
        this.has_moved = (Math.abs(evt.pageX - this.grab_origin) >= 15) ? true : false;

        if (dist > 0) {

            this.elm_slider.style.left = 0;
        } else if (dist < this.max_scroll) {

            this.elm_slider.style.left = this.max_scroll + 'px';
        }

        this.elm_slider.classList.remove('grabbed');

        return dist;
    };

    TouchScroller.prototype.move = function (evt) {

        if (this.is_grabbed) {

            this.mv_origin = parseFloat(this.elm_slider.style.left) || 0,
            this.mv_offset = this.mv_origin + (evt.pageX - this.touch_orig);

            this.touch_orig = evt.pageX;

            if (this.mv_offset <= 0 && this.mv_offset >= this.max_scroll) {

                this.computePercent();
                this.computePage();

                this.tracker.handler.style.left = (this.tracker.tracker.offsetWidth * (this.pc_offset / 100)) + 'px';

                this.elm_slider.style.left = this.mv_offset + 'px';

                if ((this.mv_offset <= this.max_scroll + 340) && (window.infinity !== undefined) && infinity.data.status !== 2) {

                    infinity.fn.load();
                    this.is_grabbed = false;
                }
            }
        }

        return evt;
    };

    TouchScroller.prototype.updateWidth = function () {

        var that = this,
            load = document.getElementById('filter_loader'),
            dist, left;

        this.slides = this.elm_slider.getElementsByClassName('cadre1_horiz');

        this.nbr_slide = this.slides.length;
        this.max_width = 340 * Math.ceil(this.nbr_slide / 2);
        this.max_scroll = ((1295 - this.max_width) < 0) ? (1295 - this.max_width) : 0;

        this.max_page = Math.ceil(this.max_width / (340 * 4)) - 1;

        this.elm_slider.style.width = this.max_width + 'px';

        if (parseFloat(this.elm_slider.style.left) < this.max_scroll) {

            this.mv_origin = parseFloat(this.elm_slider.style.left) || 0,
            this.mv_offset = this.max_scroll;

            this.touch_orig = this.mv_offset;

            dist = Math.abs(this.mv_offset - this.mv_origin);
            dist = (dist <= 1295) ? dist : 1295;

            this.computePercent(this.mv_offset);
            this.computePage(this.mv_offset);

            $(this.tracker.handler).animate({ left : that.tracker.tracker.offsetWidth * (that.pc_offset / 100) }, dist);
            $(this.elm_slider).animate({ left : this.mv_offset }, dist);
        } else {

            this.computePercent();

            this.computePage();

            left = {};

            left.old = parseFloat(this.tracker.handler.style.left);
            left.new = that.tracker.tracker.offsetWidth * (that.pc_offset / 100);

            dist = Math.abs(left.new - left.old);
            dist = (dist <= 1295) ? dist : 1295;

            $(this.tracker.handler).animate({ left : left.new }, dist);
        }

        if (load && load.style.display === 'block') {

            $(load).fadeOut();
        }
    }

    TouchScroller.prototype.computePercent = function (dist) {

        var offset = (dist !== undefined) ? dist : parseFloat(this.elm_slider.style.left);

        if (offset === 0) {

            this.pc_offset = 0;
        } else {

            this.pc_offset = Math.abs(Math.round((offset / this.max_scroll) * 100));
        }

        return offset;
    };

    TouchScroller.prototype.computePage = function (dist) {

        var offset = (dist !== undefined) ? dist : parseFloat(this.elm_slider.style.left);

        if (offset === 0) {

            this.cur_page = 0;
        } else {

            this.cur_page = Math.floor(Math.abs(offset) / (340 * 4));
        }

        return offset;
    };

    TouchScroller.prototype.goToPrev = function (force) {

        var that = this, dist;

        if (!this.is_moving) {

            this.is_moving = true;

            this.cur_page = (this.cur_page - 1 > 0) ? this.cur_page - 1 : 0;

            this.mv_origin = parseFloat(this.elm_slider.style.left) || 0,
            this.mv_offset = -(this.cur_page * (340 * 4));

            if (this.mv_offset > 0) {

                this.mv_offset = 0;
            } else if (this.mv_offset < this.max_scroll) {

                this.mv_offset = this.max_scroll;
            }

            this.touch_orig = this.mv_offset;

            dist = Math.abs(this.mv_offset - this.mv_origin);
            dist = (dist <= 1295) ? dist : 1295;

            this.computePercent(this.mv_offset);

            if ((this.mv_offset <= this.max_scroll + 340) && (window.infinity !== undefined) && infinity.data.status !== 2 && !force) {

                this.is_moving = false;

                infinity.fn.load('goToPrev');
            } else {

                $(this.tracker.handler).stop(true, false).animate({ left : that.tracker.tracker.offsetWidth * (that.pc_offset / 100) }, dist);
                $(this.elm_slider).animate({ left : this.mv_offset }, dist, function () {

                    that.is_moving = false;
                });
            }
        }
    };

    TouchScroller.prototype.goToNext = function (force) {

        var that = this, dist;

        if (!this.is_moving) {

            this.is_moving = true;

            this.cur_page = (this.cur_page + 1 < this.max_page) ? this.cur_page + 1 : this.max_page;

            this.mv_origin = parseFloat(this.elm_slider.style.left) || 0,
            this.mv_offset = -(this.cur_page * (340 * 4));

            if (this.mv_offset > 0) {

                this.mv_offset = 0;
            } else if (this.mv_offset < this.max_scroll) {

                this.mv_offset = this.max_scroll;
            }

            this.touch_orig = this.mv_offset;

            dist = Math.abs(this.mv_offset - this.mv_origin);
            dist = (dist <= 1295) ? dist : 1295;

            this.computePercent(this.mv_offset);

            if ((this.mv_offset <= this.max_scroll + 340) && (window.infinity !== undefined) && infinity.data.status !== 2 && !force) {

                this.is_moving = false;

                infinity.fn.load('goToNext');
            } else {

                $(this.tracker.handler).stop(true, false).animate({ left : that.tracker.tracker.offsetWidth * (that.pc_offset / 100) }, dist);
                $(this.elm_slider).animate({ left : this.mv_offset }, dist, function () {

                    that.is_moving = false;
                });
            }
        }
    };

    TouchScroller.prototype.computeMvOff = function (page) {

        var page = $('.infinity_page', this.elm_slider).get(page);

        return Math.max(-(page.offsetLeft), this.max_scroll);
    }

    TouchScroller.prototype.goToPage = function (page) {

        this.cur_page = page - 1;
        this.mv_offset = this.computeMvOff(page);

        this.computePercent(this.mv_offset);

        $(this.tracker.handler).css({ left : this.tracker.tracker.offsetWidth * (this.pc_offset / 100) });
        $(this.elm_slider).css({ left : this.mv_offset });
    };

    window.TouchScroller = TouchScroller;
}());
